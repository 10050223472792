export const homepage = {
  description: {
    breadcrumb: {
      homepage: "صفحه اصلی",
      about_snapptrip: "درباره اسنپ‌تریپ"
    },
    about_snapptrip: {
      title: "درباره اسنپ‌تریپ"
    },
    travel_services: {
      title: "خدمات مسافرتی اسنپ‌تریپ در یک نگاه",
      hotel_domestic: "هتل داخلی",
      hotel_international: "هتل خارجی",
      flights_domestic: "پرواز داخلی",
      flights_international: "پرواز خارجی",
      train: "قطار",
      bus: "اتوبوس",
      tour: "تور",
      villa: "ویلا",
      visa: "ویزا و CIP"
    }
  },
  no_result: {
    title: "نتیجه ای یافت نشد.",
    description_first_part: "نتیجه ای برای ",
    description_second_part: " پیدا نکردیم.",
    search_another_word: "با کلمه ی دیگری جستجو کنید.",
    search_count: "بیشتر از {count} مورد را گشتیم ",
    but: "اما"
  },
  services: {
    hotels: "هتل",
    inter_hotels: "هتل خارجی",
    flights: "پرواز",
    domestic_hotels: "هتل داخلی",
    domestic_flights: "پرواز داخلی",
    inter_flights: "پرواز خارجی",
    bus: "اتوبوس",
    bus_domestic: "اتوبوس داخلی",
    bus_international: "اتوبوس خارجی",
    stays: "ویلا",
    tour: "تور",
    tours: "تورهای مسافرتی",
    train: "قطار",
    visa: "ویزا"
  },
  sale_type: {
    title: "نوع پرواز"
  },
  night_count: {
    title: "تعداد شب"
  },
  b2: {
    title: "پیشنهاد ویژه",
    close: "بستن",
    see_more: "مشاهده بیشتر"
  },
  special_contents: {
    show_all: "نمایش همه",
    show_content: "نمایش مطلب"
  },
  main_search: {
    to_n_age: "تا {age} سال",
    m_to_n_age: "{age_1} تا {age_2} سال",
    under_age: "زیر {age} سال",
    age: "{age} سال",
    number_of_adult: "تعداد بزرگسال",
    number_of_child: "تعداد کودک",
    greather_than_n_years: "{age} سال به بالا",
    to_n_years: "تا {age} سال",
    number_of_passenger_and_room: "تعداد مسافر و اتاق",
    x_passenger_and_y_room: "{passenger_count} مسافر، {room_count} اتاق",
    room: "اتاق",
    delete_title: "حذف",
    add_room: "افزودن اتاق",
    child_age: "سن کودک {num}",
    child_age_is_mandatory: "سن کودک اجباری است",
    youre_recent_search: "جستجو های اخیر",
    passenger: "مسافر",
    warning: {
      valid_max_adult_count:
        "سقف تعدا بزرگسال و کودک برای هر اتاق {count} نفر میباشد",
      valid_passenger_count: "سقف تعداد مسافر {count} نفر می باشد.",
      valid_max_room_count: "سقف تعداد اتاق {count} است."
    }
  },
  hotel: {
    main_search: {
      check_in: "تاریخ ورود",
      check_out: "تاریخ خروج",
      destination: "شهر یا هتل مقصد",
      number_of_adult: "تعداد بزرگسال",
      number_of_child: "تعداد کودک",
      number_of_room: "تعداد اتاق",
      greather_than_n_years: "{age} سال به بالا",
      between_m_and_n_years: "{age_one} تا {age_two} سال",
      number_of_passenger_and_room: "تعداد مسافر و اتاق",
      x_passenger_and_y_room: "{passenger_count} مسافر، {room_count} اتاق"
    },
    suggestions: {
      title: "هتل‌های پیشنهادی",
      title_snappir: "هتل‌های پیشنهادی اسنپ",
      subtitle:
        "برای دیدن هتل‌های پیشنهادی هر شهر لطفا مقصد خود را انتخاب کنید",
      zero: "صفر",
      one: "یک",
      two: "دو",
      three: "سه",
      four: "چهار",
      five: "پنج",
      count_stars: "{star} ستاره",
      price_per_night: "قیمت هر شب",
      toman: "تومان"
    },
    popular_cities: {
      title: "محبوبترین شهرهای ایران"
    },
    features: {
      title:
        "اسنپ‌تریپ ! رزرو هتل٬ اقامتگاه و ویلا٬ خرید بلیط هواپیما٬ قطار٬ اتوبوس و انواع تور",
      online_reserve: {
        title: "رزرو کاملا آنلاین",
        description: "رزرو هتل و خرید بلیط راحت و سریع"
      },
      best_price: {
        title: "تضمین بهترین قیمت",
        description: "بیشترین تخفیفها برای رزرو هتل و خرید بلیط"
      },
      support: {
        title: "پشتیبانی ۲۴ ساعته",
        description: "پشتیبانی ۷ روز هفته و روزهای تعطیل"
      },
      safe_choice: {
        title: "امکان بررسی نظرات کاربران",
        description: "بررسی کیفیت هتل ها از دید کاربران"
      }
    },
    special_contents: {
      title: "آنچه باید درباره‌ی رزرو هتل بدانید"
    },
    faq: {
      title: "پاسخ به برخی از سوالات"
    },
    inbound_links: {
      title: "سفر بعدیت رو از الان برنامه ریزی کن"
    },
    last_second: {
      view_more: "مشاهده بیشتر",
      adults_count: "{adults} نفر",
      tonight_price: "قیمت امشب",
      until_end: "تا پایان",
      tooman_currency: "تومان",
      main_title: "هتل‌های لحظه آخری امروز",
      all_last_seconds_of: "همه لحظه آخری‌های {city}",
      only_left_number_of_rooms: "فقط {count} اتاق باقی مانده",
      hotel_card: {
        view_and_reserve: "مشاهده و رزرو",
        pictures: "تصاویر هتل",
        star_count: "{count} ستاره",
        breakfast: "با صبحانه"
      }
    }
  },
  flights: {
    main_search: {
      departure_date: "تاریخ رفت",
      return_date: "تاریخ برگشت",
      origin: "مبدا",
      destination: "مقصد",
      one_way: "یک طرفه",
      round_trip: "رفت و برگشت",
      passenger: "مسافر",
      number_of_adult: "تعداد بزرگسال",
      number_of_child: "تعداد کودک",
      number_of_infant: "تعداد نوزاد",
      greather_than_n_years: "{age} سال به بالا",
      between_m_and_n_years: "{age_one} تا {age_two} سال",
      number_of_passenger: "تعداد مسافر",
      x_passenger: "{passenger_count} مسافر"
    },
    features: {
      title: "اسنپ‌تریپ ! خرید بلیط پرواز داخلی و خارجی",
      online_reserve: {
        title: "خرید راحت و سریع",
        description: "ساده‌ترین فرایند جستجو، خرید و استرداد"
      },
      best_price: {
        title: "تضمین بهترین قیمت",
        description: "پایین‌ترین قیمت خرید بلیط هواپیما با تخفیف"
      },
      support: {
        title: "پشتیبانی ۲۴ ساعته",
        description: "پشتیبانی ۷ روز هفته و روزهای تعطیل"
      },
      safe_choice: {
        title: "انتخاب مطمئن",
        description: "خرید راحت بلیت پرواز سیستمی و چارتری"
      }
    },
    special_contents: {
      title: "آنچه باید درباره‌ی بلیط هواپیما بدانید"
    },
    faq: {
      title: "پاسخ به برخی از سوالات"
    },
    popular_routes: {
      title: "مسیرهای پرمخاطب",
      subtitle: "برای دیدن آفرهای هر شهر لطفا مقصد خود را انتخاب کنید",
      origin: "مبدا",
      destination: "مقصد",
      price_starts_from: "شروع قیمت از",
      toman: "تومان"
    },
    airlines: {
      title: "ایرلاین‌ها"
    },
    inbound_links: {
      title: "سفر بعدیت رو از الان برنامه ریزی کن"
    },
    sale_type: {
      title: "نوع پرواز"
    }
  },
  inter_flights: {
    main_search: {
      departure_date: "تاریخ رفت",
      return_date: "تاریخ برگشت",
      origin: "مبدا",
      destination: "مقصد",
      one_way: "یک طرفه",
      round_trip: "رفت و برگشت",
      passenger: "مسافر",
      number_of_adult: "تعداد بزرگسال",
      number_of_child: "تعداد کودک",
      number_of_infant: "تعداد نوزاد",
      greather_than_n_years: "{age} سال به بالا",
      between_m_and_n_years: "{age_one} تا {age_two} سال",
      number_of_passenger: "تعداد مسافر",
      x_passenger: "{passenger_count} مسافر",
      economy: "اکونومی",
      business: "بیزینس",
      first_class: "فرست کلاس"
    },
    features: {
      title: "اسنپ‌تریپ ! خرید بلیط پرواز داخلی و خارجی",
      online_reserve: {
        title: "خرید راحت و سریع",
        description: "ساده‌ترین فرایند جستجو، خرید و استرداد"
      },
      best_price: {
        title: "تضمین بهترین قیمت",
        description: "پایین‌ترین قیمت خرید بلیط هواپیما با تخفیف"
      },
      support: {
        title: "پشتیبانی ۲۴ ساعته",
        description: "پشتیبانی ۷ روز هفته و روزهای تعطیل"
      },
      safe_choice: {
        title: "انتخاب مطمئن",
        description: "خرید راحت بلیت پرواز سیستمی و چارتری"
      }
    },
    special_contents: {
      title: "آنچه باید درباره‌ی پرواز خارجی بدانید"
    },
    faq: {
      title: "پاسخ به برخی از سوالات"
    },
    inbound_links: {
      title: "سفر بعدیت رو از الان برنامه ریزی کن"
    },
    sale_type: {
      title: "نوع پرواز"
    }
  },
  bus: {
    main_search: {
      departure_date: "تاریخ رفت",
      origin: "مبدا",
      destination: "مقصد",
      number_of_person: "تعداد نفرات",
      number_of_passenger: "تعداد مسافر",
      x_passenger: "{passenger_count} مسافر"
    },
    features: {
      title: "اسنپ‌تریپ ! خرید بلیط پرواز داخلی و خارجی",
      online_reserve: {
        title: "خرید راحت و سریع",
        description: "ساده‌ترین فرایند جستجو، خرید و استرداد"
      },
      best_price: {
        title: "تضمین بهترین قیمت",
        description: "پایین‌ترین قیمت خرید بلیط هواپیما با تخفیف"
      },
      support: {
        title: "پشتیبانی ۲۴ ساعته",
        description: "پشتیبانی ۷ روز هفته و روزهای تعطیل"
      },
      safe_choice: {
        title: "انتخاب مطمئن",
        description: "خرید راحت بلیت پرواز سیستمی و چارتری"
      }
    },
    special_contents: {
      title: "آنچه باید درباره‌ی بلیط اتوبوس بدانید"
    },
    faq: {
      title: "پاسخ به برخی از سوالات"
    },
    inbound_links: {
      title: "سفر بعدیت رو از الان برنامه ریزی کن"
    }
  },
  bus_international: {
    main_search: {
      departure_date: "تاریخ رفت",
      origin: "مبدا",
      destination: "مقصد",
      number_of_person: "تعداد نفرات",
      number_of_passenger: "تعداد مسافر",
      x_passenger: "{passenger_count} مسافر"
    },
    features: {
      title: "اسنپ‌تریپ ! خرید بلیط پرواز داخلی و خارجی",
      online_reserve: {
        title: "خرید راحت و سریع",
        description: "ساده‌ترین فرایند جستجو، خرید و استرداد"
      },
      best_price: {
        title: "تضمین بهترین قیمت",
        description: "پایین‌ترین قیمت خرید بلیط هواپیما با تخفیف"
      },
      support: {
        title: "پشتیبانی ۲۴ ساعته",
        description: "پشتیبانی ۷ روز هفته و روزهای تعطیل"
      },
      safe_choice: {
        title: "انتخاب مطمئن",
        description: "خرید راحت بلیت پرواز سیستمی و چارتری"
      }
    },
    special_contents: {
      title: "آنچه باید درباره‌ی بلیط اتوبوس بدانید"
    },
    faq: {
      title: "پاسخ به برخی از سوالات"
    },
    inbound_links: {
      title: "سفر بعدیت رو از الان برنامه ریزی کن"
    }
  },
  villa: {
    main_search: {
      check_in: "تاریخ ورود",
      check_out: "تاریخ خروج",
      destination: "مقصد",
      number_of_person: "تعداد نفرات",
      number_of_passenger: "تعداد مسافر",
      x_passenger: "{passenger_count} مسافر",
      country: "کشور",
      state: "استان",
      city: "شهر",
      area: "ناحیه",
      village: "روستا"
    },
    features: {
      title:
        "اسنپ‌تریپ ! رزرو هتل٬ اقامتگاه و ویلا٬ خرید بلیط هواپیما٬ قطار٬ اتوبوس و انواع تور",
      online_reserve: {
        title: "رزرو کاملا آنلاین",
        description: "رزرو هتل و خرید بلیط راحت و سریع"
      },
      best_price: {
        title: "تضمین بهترین قیمت",
        description: "بیشترین تخفیفها برای رزرو هتل و خرید بلیط"
      },
      support: {
        title: "پشتیبانی ۲۴ ساعته",
        description: "پشتیبانی ۷ روز هفته و روزهای تعطیل"
      },
      safe_choice: {
        title: "امکان بررسی نظرات کاربران",
        description: "بررسی کیفیت هتل ها از دید کاربران"
      }
    },
    faq: {
      title: "پاسخ به برخی از سوالات"
    },
    inbound_links: {
      title: "سفر بعدیت رو از الان برنامه ریزی کن"
    }
  },
  tour: {
    main_search: {
      departure_date: "تاریخ رفت",
      return_date: "تاریخ برگشت",
      origin: "مبدا",
      destination: "مقصد",
      number_of_person: "تعداد نفرات",
      number_of_passenger: "تعداد مسافر",
      x_passenger: "{passenger_count} مسافر",
      tour: "تور"
    },
    night_count: {
      label: "تعداد شب",
      night: "شب",
      to: "به"
    },
    features: {
      title:
        "اسنپ‌تریپ ! رزرو هتل٬ اقامتگاه و ویلا٬ خرید بلیط هواپیما٬ قطار٬ اتوبوس و انواع تور",
      online_reserve: {
        title: "رزرو کاملا آنلاین",
        description: "رزرو هتل و خرید بلیط راحت و سریع"
      },
      best_price: {
        title: "تضمین بهترین قیمت",
        description: "بیشترین تخفیفها برای رزرو هتل و خرید بلیط"
      },
      support: {
        title: "پشتیبانی ۲۴ ساعته",
        description: "پشتیبانی ۷ روز هفته و روزهای تعطیل"
      },
      safe_choice: {
        title: "امکان بررسی نظرات کاربران",
        description: "بررسی کیفیت هتل ها از دید کاربران"
      }
    },
    faq: {
      title: "پاسخ به برخی از سوالات"
    },
    inbound_links: {
      title: "سفر بعدیت رو از الان برنامه ریزی کن"
    }
  },
  train: {
    main_search: {
      your_last_searches: "آخرین جستجوهای شما",
      one_way: "یک طرفه",
      round_trip: "رفت و برگشت",
      regular_coupe: "کوپه عادی",
      exclusive_coupe: "کوپه دربست",
      normal: "مسافرین عادی",
      male: "ویژه برادران",
      female: "ویژه خواهران",
      number_of_adult: "تعداد بزرگسال",
      number_of_child: "تعداد کودک",
      number_of_infant: "تعداد نوزاد",
      greather_than_n_years: "{age} سال به بالا",
      between_m_and_n_years: "{age_one} تا {age_two} سال",
      number_of_passenger: "تعداد مسافر",
      x_passenger: "{passenger_count} مسافر",
      origin: "مبدا",
      destination: "مقصد",
      departure_date: "تاریخ رفت",
      return_date: "تاریخ برگشت"
    },
    features: {
      title: "اسنپ‌تریپ ! خرید بلیط پرواز داخلی و خارجی",
      online_reserve: {
        title: "خرید راحت و سریع",
        description: "ساده‌ترین فرایند جستجو، خرید و استرداد"
      },
      best_price: {
        title: "تضمین بهترین قیمت",
        description: "پایین‌ترین قیمت خرید بلیط هواپیما با تخفیف"
      },
      support: {
        title: "پشتیبانی ۲۴ ساعته",
        description: "پشتیبانی ۷ روز هفته و روزهای تعطیل"
      },
      safe_choice: {
        title: "انتخاب مطمئن",
        description: "خرید راحت بلیت پرواز سیستمی و چارتری"
      }
    },
    special_contents: {
      title: "آنچه باید درباره‌ی بلیط قطار بدانید"
    },
    faq: {
      title: "پاسخ به برخی از سوالات"
    },
    popular_routes: {
      title: "مسیرهای پرمخاطب",
      subtitle: "برای دیدن آفرهای هر شهر لطفا مقصد خود را انتخاب کنید",
      origin: "مبدا",
      destination: "مقصد",
      price_starts_from: "شروع قیمت از",
      toman: "تومان"
    },
    inbound_links: {
      title: "سفر بعدیت رو از الان برنامه ریزی کن"
    },
    sale_type: {
      title: "نوع مسیر"
    },
    coupe_type: {
      title: "نوع کوپه"
    },
    salon_type: {
      title: "نوع سالن"
    },
    foriegn_people: `
    جهت صدور بلیط برای اتباع غیر ایرانی باید از گزینه "اتباع خارجی" استفاده کنید. در غیر این صورت مسئولیت و پرداخت خسارت این تخلف بر عهده کاربر و مسافر مربوطه است.`
  },
  visa: {
    main: {
      carousel: {
        header: "ویزای توریستی",
        detail: "برای دیدن جزییات قوانین لطفا کشور مقصد خود را انتخاب کنید.",
        price: "شروع قیمت از"
      }
    },
    main_search: {
      check_in: "تاریخ ورود",
      check_out: "تاریخ خروج",
      destination: "مقصد",
      number_of_person: "تعداد نفرات",
      number_of_passenger: "تعداد مسافر",
      x_passenger: "{passenger_count} مسافر",
      destination_label: "کشور مقصد",
      your_last_search: "آخرین جستجوهای شما"
    },
    features: {
      title:
        "اسنپ‌تریپ ! رزرو هتل٬ اقامتگاه و ویلا٬ خرید بلیط هواپیما٬ قطار٬ اتوبوس و انواع تور",
      online_reserve: {
        title: "رزرو کاملا آنلاین",
        description: "رزرو هتل و خرید بلیط راحت و سریع"
      },
      best_price: {
        title: "تضمین بهترین قیمت",
        description: "بیشترین تخفیفها برای رزرو هتل و خرید بلیط"
      },
      support: {
        title: "پشتیبانی ۲۴ ساعته",
        description: "پشتیبانی ۷ روز هفته و روزهای تعطیل"
      },
      safe_choice: {
        title: "امکان بررسی نظرات کاربران",
        description: "بررسی کیفیت هتل ها از دید کاربران"
      }
    },
    faq: {
      title: "پاسخ به برخی از سوالات"
    },
    inbound_links: {
      title: "سفر بعدیت رو از الان برنامه ریزی کن"
    }
  },
  inter_hotel: {
    main_search: {
      check_in: "تاریخ ورود",
      check_out: "تاریخ خروج",
      destination: "شهر یا هتل مقصد",
      number_of_adult: "تعداد بزرگسال",
      number_of_child: "تعداد کودک",
      greather_than_n_years: "{age} سال به بالا",
      between_m_and_n_years: "{age_one} تا {age_two} سال",
      to_n_years: "تا {age} سال",
      number_of_passenger_and_room: "تعداد مسافر و اتاق",
      x_passenger_and_y_room: "{passenger_count} مسافر، {room_count} اتاق",
      room: "اتاق",
      delete_title: "حذف",
      add_room: "افزودن اتاق",
      child_age: "سن کودک {num}",
      child_age_is_mandatory: "سن کودک اجباری است",
      youre_recent_search: "جستجو های اخیر",
      passenger: "مسافر",
      warning: {
        valid_max_adult_count:
          "سقف تعداد بزرگسال برای هر اتاق {count} نفر می باشد.",
        valid_max_child_count:
          "سقف تعداد کودک برای هر اتاق {count} نفر می باشد.",
        valid_max_room_count: "سقف تعداد اتاق {count} است."
      },
      less_than_n_year: "کمتر از {age} سال"
    },
    suggestions: {
      title: "هتل‌های پیشنهادی",
      subtitle:
        "برای دیدن هتل‌های پیشنهادی هر شهر لطفا مقصد خود را انتخاب کنید",
      zero: "صفر",
      one: "یک",
      two: "دو",
      three: "سه",
      four: "چهار",
      five: "پنج",
      count_stars: "{star} ستاره",
      price_per_night: "قیمت هر شب",
      toman: "تومان"
    },
    popular_cities: {
      title: "محبوبترین شهرهای ایران"
    },
    features: {
      title:
        "اسنپ‌تریپ ! رزرو هتل٬ اقامتگاه و ویلا٬ خرید بلیط هواپیما٬ قطار٬ اتوبوس و انواع تور",
      online_reserve: {
        title: "رزرو کاملا آنلاین",
        description: "رزرو هتل و خرید بلیط راحت و سریع"
      },
      best_price: {
        title: "تضمین بهترین قیمت",
        description: "بیشترین تخفیفها برای رزرو هتل و خرید بلیط"
      },
      support: {
        title: "پشتیبانی ۲۴ ساعته",
        description: "پشتیبانی ۷ روز هفته و روزهای تعطیل"
      },
      safe_choice: {
        title: "امکان بررسی نظرات کاربران",
        description: "بررسی کیفیت هتل ها از دید کاربران"
      }
    },
    special_contents: {
      title: "آنچه باید درباره‌ی رزرو هتل بدانید"
    },
    faq: {
      title: "پاسخ به برخی از سوالات"
    },
    inbound_links: {
      title: "سفر بعدیت رو از الان برنامه ریزی کن"
    }
  },
  contact_us: { page_title: "تماس با ما | اسنپ‌تریپ" }
};
