import { Checkbox, Switch } from "@website/ui";
import { useRouter } from "next/router";
import { Fragment, useCallback, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { visaTypes } from "../filter.types";

interface VisaFilterProperties extends visaTypes {
  name: string;
}

export const VisaFilter = (properties: VisaFilterProperties) => {
  const { name, title, type, options = [] } = properties;

  const { control, setValue } = useFormContext();
  const router = useRouter();

  const parsedInitialValues = useMemo(() => {
    const query = router.query[name];
    if (query === "none") {
      return true;
    }
    if (query) {
      const stringifyQuery = Array.isArray(query) ? query.join(",") : query;
      const data = stringifyQuery;
      return Object.fromEntries(data?.split(",").map((q) => [q, true]) ?? []);
    }
  }, [name, router.query]);

  useEffect(() => {
    setValue(name, { type, value: parsedInitialValues });
  }, [name, parsedInitialValues, setValue, type]);

  const checkedByQuery = useCallback(
    (optionName: string) => {
      if (!router.query[name]) return false;

      return router.query[name].toString().split(",").includes(optionName);
    },
    [name, router.query]
  );
  return (
    <Fragment>
      <div className="py-3">
        <span className="text-subtitle-1 pb-1">{title}</span>
        <div className="flex gap-4">
          <p className="text-body-2 text-on-surface-medium-emphasis">
            توقف در بعضی کشور ها نیازمند ویزا است.
          </p>
          <Controller
            control={control}
            name={`${name}.value`}
            render={({ field: { value, onChange } }) => (
              <Switch
                aria-labelledby="has-availability"
                checked={value === true || value?.["none"] || false}
                color="secondary"
                onChange={onChange}
              />
            )}
          />
        </div>
      </div>

      {options.map((option) => (
        <div key={name + option.name}>
          <Controller
            control={control}
            name={`${name}.value.${option.name}`}
            render={({ field: { onChange, value, ...field } }) => (
              <Checkbox
                checked={checkedByQuery(option.name) ?? value}
                className="text-subtitle-2 h-12"
                color="secondary"
                disabled={router.query[name] === "none"}
                label={option.label}
                onChange={onChange}
                {...field}
              />
            )}
          />
        </div>
      ))}
    </Fragment>
  );
};
