import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Button, ListItem, Modal, Popover } from "@website/ui";
import { numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { Login } from "../login";
import LOGO_B2B from "./assets/logo-b2b.svg";
import LOGO_EN from "./assets/logo-en.png";
import LOGO from "./assets/logo.png";
import LOGO_SNAPP from "./assets/logo_snapp.svg";
import { Drawer, TrackModal } from "./components";
import styles from "./header.module.scss";
import { HeaderPropertiesType } from "./header.types";
import {
  useDrawer,
  useLoginModal,
  useServices,
  useSupportMenu,
  useTrackModal
} from "./hooks";

const Header: FC<HeaderPropertiesType> = (properties) => {
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE || "web";
  const router = useRouter();

  const {
    showAuth = !["jek", "b2b"].includes(channel),
    minimalView = !["website", "web"].includes(channel),
    showBackButton = !["website", "web"].includes(channel),
    showMenuButton = !["snapp-ir", "b2b"].includes(channel),
    showDropShadow = true,
    showBottomBorder = false,
    state = "normal",
    showTrackPurchase = !["b2b"].includes(channel),
    showAgencies = !["snapp-ir", "b2b"].includes(channel),
    showTravelServices = !["snapp-ir", "b2b"].includes(channel),
    showDownloadApp = !["snapp-ir", "b2b"].includes(channel),
    showSupportAgent = !["b2b"].includes(channel),
    showBackToWebsite = ["b2b"].includes(channel) &&
      !["/"].includes(router.pathname)
  } = properties;

  const { t, locale } = useTranslation();
  const { onSupportMenuOpen, onSupportMenuClose, supportMenuIsOpen } =
    useSupportMenu();
  const { drawerIsOpen, onDrawerOpen, onDrawerClose } = useDrawer();
  const { onTrackModalOpen, trackModalProps } = useTrackModal();
  const {
    onLoginModalOpen,
    loginModalProps,
    onLoginModalClose,
    onLoginModalEnd,
    isLogin
  } = useLoginModal();
  const { services, onGoBack, onBackToWebsite } = useServices(properties);

  return (
    <div
      className={`${styles["container"]} ${
        showDropShadow ? "" : styles["without-shadow"]
      } ${showBottomBorder ? styles["with-bottom-border"] : ""} ${
        styles[state]
      }`}
    >
      <div className={styles["middle"]}>
        <nav className="flex h-full items-center gap-2 lg:gap-4">
          {showMenuButton && !minimalView && (
            <Button
              EndIcon={<MaterialIcon name="menu" />}
              aria-label="open menu"
              className={styles["menu-button"]}
              color="neutral"
              equalDimension
              onClick={onDrawerOpen}
              size="small"
              variant="text"
            />
          )}

          {showBackButton && (
            <Button
              EndIcon={<MaterialIcon name="arrow_forward" variant="outlined" />}
              aria-label="close"
              className={styles["back-button"]}
              color="neutral"
              equalDimension
              onClick={onGoBack}
              size="small"
              variant="text"
            />
          )}

          <a aria-label="snapptrip" href={process.env.NEXT_PUBLIC_BASE_URL_B2C}>
            <img
              alt="snapptrip logo"
              className={styles["logo"]}
              height={78}
              loading="lazy"
              src={
                process.env["NEXT_PUBLIC_CHANNEL_TYPE"] === "snapp-ir"
                  ? LOGO_SNAPP.src
                  : process.env["NEXT_PUBLIC_CHANNEL_TYPE"] === "b2b"
                    ? LOGO_B2B.src
                    : locale === "en"
                      ? LOGO_EN.src
                      : LOGO.src
              }
              title={t("header.logo")}
              width={137}
            />
          </a>
          {!minimalView && (
            <>
              <div className={styles["services-wrapper"]}>
                {showTravelServices && (
                  <Button
                    EndIcon={<MaterialIcon name="keyboard_arrow_down" />}
                    aria-label={t("header.travel_services")}
                    color="neutral"
                    size="medium"
                    variant="text"
                  >
                    {t("header.travel_services")}
                  </Button>
                )}
                <div className={styles["service-menu-container"]}>
                  <div className={styles["service-menu"]}>
                    <div className={styles["menu"]}>
                      {services?.map((item) => (
                        <a
                          className={styles["menu-item"]}
                          href={item.url}
                          key={item.title}
                        >
                          <span className={styles["menu-item-icon"]}>
                            {item.icon}
                          </span>
                          <div className={styles["menu-item-title"]}>
                            {item.title}
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {showAgencies && (
                <Button
                  className={styles["agencies-button"]}
                  color="neutral"
                  href="https://business.snapptrip.com"
                  role="link"
                  size="medium"
                  target="_blank"
                  variant="text"
                >
                  {t("header.agencies")}
                </Button>
              )}
            </>
          )}
        </nav>

        <div className={styles["divider"]} />
        {!minimalView && (
          <>
            {showDownloadApp && (
              <Button
                className={styles["download-app"]}
                color="neutral"
                href={`${process.env.NEXT_PUBLIC_BASE_URL_B2C}/app`}
                role="link"
                size="medium"
                target="_blank"
                variant="text"
              >
                {t("header.download_app")}
              </Button>
            )}

            {showTrackPurchase && (
              <>
                <Button
                  className={styles["track-order"]}
                  color="neutral"
                  onClick={onTrackModalOpen}
                  size="medium"
                  variant="text"
                >
                  {t("header.track_order")}
                </Button>

                <Button
                  EndIcon={
                    <MaterialIcon name="assignment" variant="outlined" />
                  }
                  aria-label={t("header.track_order")}
                  className={styles["track-order-small"]}
                  color="neutral"
                  equalDimension
                  onClick={onTrackModalOpen}
                  role="button"
                  size="medium"
                  variant="text"
                />
              </>
            )}

            {showBackToWebsite && (
              <Button
                EndIcon={
                  <MaterialIcon name="chevron_left" variant="outlined" />
                }
                className={styles["track-order"]}
                color="neutral"
                onClick={onBackToWebsite}
                size="medium"
                variant="text"
              >
                {t("header.normal_user")}
              </Button>
            )}
          </>
        )}
        <div className={styles["support-wrapper"]}>
          {showSupportAgent && (
            <Button
              EndIcon={<MaterialIcon name="support_agent" />}
              aria-label={t("header.show_support_links")}
              color="neutral"
              equalDimension
              onClick={onSupportMenuOpen}
              role="button"
              size="medium"
              variant="text"
            />
          )}

          <Popover
            gap="16px"
            horizontalPosition="end"
            onClose={onSupportMenuClose}
            open={supportMenuIsOpen}
          >
            <div className={styles["support-menu"]}>
              {["website", "web"].includes(channel) && (
                <ListItem
                  StartIcon={<MaterialIcon name="help_outline" />}
                  aria-label={t("header.faq")}
                  // href="http://b2c.ptp/faq"
                  href={`${process.env.NEXT_PUBLIC_BASE_URL_B2C}/faq`}
                  role="link"
                  target="_blank"
                  title={t("header.faq")}
                />
              )}

              <ListItem
                StartIcon={<MaterialIcon name="support_agent" />}
                aria-label={t("header.call_support")}
                href="tel:+982192000123"
                role="link"
                subtitle={
                  <div className={styles["support-menu-phone-number"]}>
                    {numberConvert("+98 21 92000123", {
                      locale
                    })}
                  </div>
                }
                title={t("header.call_support")}
              />
            </div>
          </Popover>
        </div>

        {showAuth && !["ads"].includes(channel) ? (
          isLogin ? (
            <>
              {/* mobile */}
              <Button
                EndIcon={<MaterialIcon name="person" />}
                aria-label={t("header.account")}
                className={styles["account-button-small"]}
                color="secondary"
                equalDimension
                href={`${process.env.NEXT_PUBLIC_BASE_URL_B2C}/profile`}
                role="link"
                size="small"
                variant="text"
              />

              {/* desktop */}
              <Button
                EndIcon={<MaterialIcon name="keyboard_arrow_down" />}
                StartIcon={<MaterialIcon name="person" />}
                className={styles["account-button"]}
                color="secondary"
                href={`${process.env.NEXT_PUBLIC_BASE_URL_B2C}/profile`}
                role="link"
                size="medium"
                variant="round-outline"
              >
                {t("header.account")}
              </Button>
            </>
          ) : (
            <>
              {/* mobile */}
              <Button
                EndIcon={<MaterialIcon name="person" />}
                aria-label={`${t("header.login")} | ${t("header.sign_up")}`}
                className={styles["login-button-small"]}
                color="secondary"
                equalDimension
                // href={`${web}/login`}
                // role="link"
                onClick={onLoginModalOpen}
                role="button"
                size="small"
                target="_blank"
                variant="round"
              />

              {/* desktop */}
              <Button
                aria-label={`${t("header.login")} ${t("header.and")} ${t(
                  "header.sign_up"
                )}`}
                className={styles["login-button"]}
                color="secondary"
                // href={`${web}/login`}
                // role="link"
                onClick={onLoginModalOpen}
                role="button"
                size="medium"
                target="_blank"
                variant="round"
              >
                {t("header.login")} | {t("header.sign_up")}
              </Button>
            </>
          )
        ) : null}
      </div>
      <Drawer onClose={onDrawerClose} open={drawerIsOpen} services={services} />
      <Modal {...trackModalProps}>
        <TrackModal />
      </Modal>
      <Modal {...loginModalProps}>
        <Login onClose={onLoginModalClose} onEndAuth={onLoginModalEnd} />
      </Modal>
    </div>
  );
};

const HeaderMemoized = memo(Header);
export { HeaderMemoized as Header };
