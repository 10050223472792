import { Checkbox } from "@website/ui";
import { useRouter } from "next/router";
import { Fragment, useCallback, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FilterCollapse } from "../collapse";
import { MultiCheckboxTypes } from "../filter.types";

interface MultiCheckboxFilterProperties extends MultiCheckboxTypes {
  name: string;
}
const parseValues = (query?: string | Array<string>) => {
  const stringifyQuery = Array.isArray(query) ? query.join(",") : query;
  const data = stringifyQuery;
  return Object.fromEntries(data?.split(",").map((q) => [q, true]) ?? []);
};

export const MultiCheckbox = (properties: MultiCheckboxFilterProperties) => {
  const { title, type, options } = properties;

  const router = useRouter();
  const { control, setValue } = useFormContext();

  useEffect(() => {
    options?.map((option) =>
      setValue(option.key, {
        type,
        value: parseValues(router.query[option.key])
      })
    );
  }, [options, router.query, setValue, type]);
  const checkedByQuery = useCallback(
    (key: string, optionName: string) => {
      if (!router.query[key]) return false;
      return router.query[key].toString().split(",").includes(optionName);
    },
    [router.query]
  );

  return (
    <Fragment>
      <div className="py-2">{title}</div>
      {options?.map(
        (item) =>
          Array.isArray(item.options) &&
          item.options.length > 0 && (
            <>
              <p className="text-caption text-on-surface-medium-emphasis">
                {item.description}
              </p>
              <FilterCollapse active={item.collapsible}>
                {item.options.map((option) => (
                  <div className="flex items-center justify-between">
                    <Controller
                      control={control}
                      name={`${item.key}.value.${option.name}`}
                      render={({ field: { onChange, value, ...field } }) => (
                        <Checkbox
                          checked={
                            checkedByQuery(item.key, option.name) ?? value
                          }
                          className="text-subtitle-2 h-12 w-full flex-auto"
                          color="secondary"
                          label={option.label}
                          onChange={onChange}
                          {...field}
                        />
                      )}
                    />
                    <p className="text-caption text-on-surface-high-emphasis">
                      {option.name}
                    </p>
                  </div>
                ))}
              </FilterCollapse>
            </>
          )
      )}
    </Fragment>
  );
};
