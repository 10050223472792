import { AxiosResponse } from "axios";
import { AxiosNoAuth } from "../../../../utils";
import {
  SearchTextParametersType,
  SearchTextResponseType
} from "./search-text.types";
export const SearchTextUrl = `${process.env.NEXT_PUBLIC_BASE_URL_HAPI}/hotel/api/v2/search-text`;

export const SearchTextService = (
  parameters: SearchTextParametersType
): Promise<SearchTextResponseType> =>
  AxiosNoAuth.get(SearchTextUrl, {
    params: parameters
  }).then(({ data }: AxiosResponse<SearchTextResponseType>) => data);
