import {
  LocalStorageProvider,
  SessionStorageProvider,
  useAuth
} from "@website/hooks";
import "@website/ui/styles";
import type { AppProps } from "next/app";
import Head from "next/head";
import { Layout } from "../components";
// import { useRouter } from "next/router";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { YupProvider } from "@website/form";
import { LocaleType, TranslationProvider } from "@website/locale";
import { startUserTracking } from "@website/tracking";
import { SnackbarProvider } from "@website/ui";
import { Scripts } from "@website/widget";
import { QueryClient, QueryClientProvider } from "react-query";
import { SettingsProvider } from "../providers";
// import { TemporaryToggleTheme } from "../components";

const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;

startUserTracking({
  product: () =>
    (
      ({
        "/": "dom-hotel",
        "/launcher": "dom-hotel",
        "/international-hotel": "int-hotel",
        "/home-visa": "int-hotel",
        "/flights": "dom-flight",
        "/flights-home": "dom-flight",
        "/interflights": "int-flight",
        "/inter-flights": "int-flight",
        "/bus": "dom-bus",
        "/bus-international": "int-bus",
        "/villa": "villa",
        "/tour": "tour",
        "/train": "dom-train",
        "/international-train": "int-train"
      }) as const
    )[new URL(window.location.href).pathname]
});

export const growthbook = new GrowthBook({
  apiHost:
    process.env["NEXT_PUBLIC_CHANNEL_TYPE"] === "ads"
      ? "https://gpi.snapptrip.info"
      : "https://gpi.snapptrip.com",
  clientKey: "sdk-Z2itvy5yPKT5xu",
  enableDevMode: true,
  subscribeToChanges: true
  // trackingCallback: (experiment, result) => {
  //   // TODO: Use your real analytics tracking system
  //   // console.log("Viewed Experiment", {
  //   //   experimentId: experiment.key,
  //   //   variationId: result.key
  //   // });
  // }
});
growthbook.init({
  // Optional, enable streaming updates
  streaming: true
});

const gtmLicenseCode =
  channel === "jek"
    ? "GTM-P8WGVRK"
    : channel === "ads"
      ? "GTM-KJPQFH4"
      : "GTM-MT54VMN";
const webEngageLicenseCode = channel === "jek" ? "~47b6574d" : "11b5650c0";

const MyApp = ({ Component, pageProps }: AppProps) => {
  useAuth();
  const queryClient = new QueryClient();

  return (
    <>
      <Head>
        <title>SnappTrip</title>
        {process.env["NEXT_PUBLIC_CHANNEL_TYPE"] !== "web" && (
          <meta content="noindex,nofollow" name="robots" />
        )}
        <meta
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
          name="viewport"
        />
        <meta content="SnappTrip" name="application-name" />
        <meta content="no" name="apple-mobile-web-app-capable" />
        <meta content="SnappTrip" name="apple-mobile-web-app-title" />
        <meta content="SnappTrip dashboard" name="description" />
        <meta content="yes" name="mobile-web-app-capable" />
        <meta content="/icons/browserconfig.xml" name="msapplication-config" />
        <meta content="#ffffff" name="msapplication-TileColor" />
        <meta content="no" name="msapplication-tap-highlight" />
        <meta content="#ffffff" name="theme-color" />
        <meta content="black" name="mobile-web-app-status-bar-style" />
        <meta content="black" name="apple-mobile-web-app-status-bar-style" />
        <link
          crossOrigin="anonymous"
          href="https://storage.googleapis.com"
          rel="preconnect"
        />
      </Head>
      <GrowthBookProvider growthbook={growthbook}>
        <QueryClientProvider client={queryClient}>
          <LocalStorageProvider prefix="homepage" version={15}>
            <SessionStorageProvider prefix="homepage" version={14}>
              <SettingsProvider>
                <TranslationProvider locale={"fa" as LocaleType}>
                  <YupProvider>
                    <SnackbarProvider duration="long">
                      <Layout>
                        <Component {...pageProps} />
                      </Layout>
                    </SnackbarProvider>
                  </YupProvider>
                  {/* <TemporaryToggleTheme /> */}
                </TranslationProvider>
              </SettingsProvider>
            </SessionStorageProvider>
          </LocalStorageProvider>
        </QueryClientProvider>
      </GrowthBookProvider>
      <Scripts
        gtmLicence={gtmLicenseCode}
        webengageLicence={webEngageLicenseCode}
      />
    </>
  );
};

export default MyApp;
