export const SUGGESTIONS_LIST = [
  {
    id: "100008",
    title: "تهران",
    slug: "100008",
    hotel_count: 549,
    info: "549 اقامتگاه",
    isHotel: false,
    entity: "STATE" as const,
    type: "state"
  },
  {
    id: "6497",
    title: "مشهد",
    slug: "6497",
    hotel_count: 123,
    info: "123 اقامتگاه",
    isHotel: false,
    entity: "CITY" as const,
    type: "city"
  },
  {
    id: "100025",
    title: "گیلان",
    slug: "100025",
    hotel_count: 290,
    info: "290 اقامتگاه",
    isHotel: false,
    entity: "STATE" as const,
    type: "state"
  },
  {
    id: "100027",
    title: "مازندران",
    slug: "100027",
    hotel_count: 261,
    info: "261 اقامتگاه",
    isHotel: false,
    entity: "STATE" as const,
    type: "state"
  },
  {
    id: "7",
    title: "کردان",
    slug: "kordan-village",
    hotel_count: 8,
    info: "8 اقامتگاه",
    isHotel: false,
    entity: "VILLAGE" as const,
    type: "village"
  },
  {
    id: "6640",
    title: "شیراز",
    slug: "6640",
    hotel_count: 234,
    info: "234 اقامتگاه",
    entity: "CITY" as const,
    type: "city"
  },
  {
    id: "100004",
    title: "اصفهان",
    slug: "100004",
    hotel_count: 149,
    info: "149 اقامتگاه",
    entity: "STATE" as const,
    type: "state"
  },
  {
    id: "6918",
    title: "کیش",
    slug: "6918",
    hotel_count: 110,
    info: "110 اقامتگاه",
    entity: "CITY" as const,
    type: "city"
  },
  {
    id: "100019",
    title: "قشم",
    slug: "100019",
    hotel_count: 20,
    info: "20 اقامتگاه",
    entity: "STATE" as const,
    type: "state"
  }
];

export const VILLA_SEARCH_HISTORY_LOCAL_STORAGE_KEY = "villa-search-history";
export const VILLA_DESTINATION_HISTORY_LOCAL_STORAGE_KEY =
  "villa-destination-history";

export const enum REGION_NAMES {
  CITY = "CITY",
  STATE = "STATE",
  VILLAGE = "VILLAGE"
}
