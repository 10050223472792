import { FALLBACK_IMAGE_SM, LazyImage } from "@website/ui";
import { deepCompare } from "@website/utils";
import { useRouter } from "next/router";
import { memo, useCallback, useEffect, useRef } from "react";
import { ReserveGallerySingle } from "../../reserve-gallery-detail/reserve-gallery-single";
import { ReserveCommentPicturesTypes } from "./reserve-comment-pictures.types";

const Component = ({
  id,
  gallery,
  roomName,
  standNights,
  registeredDate
}: ReserveCommentPicturesTypes) => {
  const openModalCondition = "gallery-comment";
  const router = useRouter();
  const selectedComment = useRef(0);
  const singleProperties = {
    gallery,
    isInsideComment: true,
    insideCommentProps: {
      roomName,
      standNights,
      registeredDate,
      openModalCondition
    }
  };

  useEffect(() => {
    if (router.query?.modal) selectedComment.current = 0;
  }, [router.query]);

  const onClick = useCallback(
    (item: string, id: number) => {
      selectedComment.current = id;
      router.push(
        {
          pathname: router.pathname,
          query: { ...router.query, modal: openModalCondition, item }
        },
        undefined,
        { shallow: true, scroll: false }
      );
    },
    [router]
  );
  return (
    <div className="flex flex-row flex-wrap justify-start gap-4">
      {gallery.map((picture, index) => (
        <LazyImage
          className="h-[60px] w-[74px] cursor-pointer rounded-lg"
          fallback={FALLBACK_IMAGE_SM.src}
          id={String(picture?.id)}
          onClick={() => onClick(String(index), id)}
          src={picture?.media}
        />
      ))}
      {id === selectedComment.current && (
        <ReserveGallerySingle {...singleProperties} />
      )}
    </div>
  );
};

export const ReserveCommentPictures = memo(Component, deepCompare);
