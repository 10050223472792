import { useTranslation } from "@website/locale";
import { Header, HeaderJek } from "@website/widget";
import { FC } from "react";
import SnappTripPersianLogo from "../../public/images/snapp-trip-logo-persian.svg";
import SnappTripLogo from "../../public/images/snapp-trip-logo.svg";
import { useHeaderWrapper } from "./header-wrapper.hook";
import styles from "./header-wrapper.module.scss";
import { HeaderWrapperPropertiesType } from "./header-wrapper.types";

export const HeaderWrapper: FC<HeaderWrapperPropertiesType> = (properties) => {
  const { headerProperties, headerJekProperties, scrolled } =
    useHeaderWrapper(properties);
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;

  const { locale } = useTranslation();

  return (
    <header className={styles["container"]}>
      <nav
        className={`${styles["fix-layer"]} ${
          scrolled ? styles["scrolled"] : ""
        }`}
      >
        {channel === "jek" ? (
          <HeaderJek {...headerJekProperties} />
        ) : channel === "ads" ? (
          <a aria-label="snapptrip" href={process.env.NEXT_PUBLIC_BASE_URL_B2C}>
            {locale === "en" && SnappTripLogo.src && (
              <img
                alt="snapptrip logo"
                className={styles["logo"]}
                height={78}
                loading="lazy"
                src={
                  locale === "en" ? SnappTripLogo.src : SnappTripPersianLogo.src
                }
                width={137}
              />
            )}
            {locale !== "en" && SnappTripPersianLogo.src && (
              <img
                alt="snapptrip logo"
                className={styles["logo"]}
                height={78}
                loading="lazy"
                src={SnappTripPersianLogo.src}
                width={137}
              />
            )}
          </a>
        ) : (
          <Header {...headerProperties} />
        )}
      </nav>
    </header>
  );
};
