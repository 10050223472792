import { FC, memo } from "react";
import { useSheet } from "./sheet.hook";
import styles from "./sheet.module.scss";
import { SheetPropertiesType } from "./sheet.types";

const Sheet: FC<SheetPropertiesType> = (properties) => {
  const {
    animate = "bottom",
    xGap = "0px",
    xPosition = "start",
    yPosition = "start",
    // isInsidePosition = false,
    children,
    fillContainerWidth
  } = properties;
  const { rootProperties, animateState } = useSheet(properties);

  if (
    animateState === "initial" ||
    animateState === "entered" ||
    animateState === "entering" ||
    animateState === "exiting"
  ) {
    return (
      <div
        className={`${styles["container"]} ${
          fillContainerWidth ? styles["fill-container-width"] : ""
        } ${styles[`animate-${animate}`]} ${styles[animateState]} ${
          styles[`x-${xPosition}`]
        } ${styles[`y-${yPosition}`]} }`}
        style={{ marginTop: xGap }}
        {...rootProperties}
      >
        {children}
      </div>
    );
  }
  return null;
};

const SheetMemoized = memo(Sheet);
export { SheetMemoized as Sheet };
