import { useTranslation } from "@website/locale";
import { authStore } from "@website/store";
import { driver } from "@website/ui";
import { Driver } from "driver.js";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";

const products: Record<string, string> = {
  "/": "DH",
  "/رزرو-هتل": "DH",
  "/train": "Train",
  "/interflights": "IF",
  "/inter-flights": "IF",
  "/flights": "DF",
  "/bus": "Bus",
  "/bus-international": "IB",
  "/international-hotel": "IH",
  "/tour": "Tour",
  "/villa": "Villa"
};

export const useLogin = () => {
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const router = useRouter();
  const { t } = useTranslation();
  const driverObject = useRef<Driver>();
  const [isProfileButtonExist, setIsProfileButtonExist] = useState(false);
  const product =
    products[
      decodeURIComponent(router?.basePath) ||
        decodeURIComponent(router?.pathname)
    ];

  useEffect(() => {
    setIsProfileButtonExist(Boolean(product));
  }, [router, product]);

  const onProfileClick = useCallback(() => {
    window?.webengage?.track("st-profile-icon-clicked", {
      product
    });
    driverObject.current?.destroy();
    localStorage.setItem("PROFILE_BUTTON_INTRO", "true");
    const query = {
      ...router.query,
      source: "profile",
      ...(product ? { product } : {})
    };
    const queryString = new URLSearchParams(query).toString();

    window.location.href = `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/profile${
      queryString ? `?${queryString}` : ""
    }`;
  }, [router, product]);

  const initDriver = useCallback(
    (driverObject: Driver) => {
      driverObject.highlight({
        element: "#profile-icon",
        popover: {
          title: t("profile.general.driverjs.profile_btn.title"),
          description: t("profile.general.driverjs.profile_btn.description"),
          nextBtnText: t("profile.general.driverjs.profile_btn.done"),
          prevBtnText: "",
          showButtons: ["next", "previous"],
          disableButtons: ["previous"],
          onNextClick: () => {
            driverObject.destroy();
            localStorage.setItem("PROFILE_BUTTON_INTRO", "true");
          },
          side: "bottom"
        },
        onDeselected: () => {
          driverObject.destroy();
          localStorage.setItem("PROFILE_BUTTON_INTRO", "true");
        }
      });
    },
    [t]
  );

  useEffect(() => {
    const profileButtonIntro = localStorage.getItem("PROFILE_BUTTON_INTRO");
    if (
      authStore.accessToken.get() &&
      isProfileButtonExist &&
      !profileButtonIntro &&
      channel === "jek"
    ) {
      if (driverObject.current) {
        initDriver(driverObject.current);
      } else {
        driver().then(({ driver }) => {
          driverObject.current = driver();
          initDriver(driverObject.current);
        });
      }
    }
  }, [channel, initDriver, isProfileButtonExist, t]);
  return {
    showAuth: isProfileButtonExist && Boolean(authStore.accessToken.get()),
    onProfileClick
  };
};
