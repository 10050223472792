import { pick, toString } from "lodash";
import { FC, memo, useEffect, useRef, useState } from "react";
import { LazyImagePropertiesType } from "./lazy-image.types";

const LazyImage: FC<LazyImagePropertiesType> = (properties) => {
  const {
    src,
    alt,
    className,
    fallback,
    loading = "lazy",
    decoding = "async"
  } = properties;
  const imageReference = useRef<HTMLImageElement>(null);
  const [imageLoaded, setImageLoaded] = useState(loading !== "lazy");
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    const reference = imageReference;
    if (reference.current?.complete) setImageLoaded(true);
    reference.current?.addEventListener(
      "load",
      setImageLoaded.bind(null, true)
    );
    reference.current?.addEventListener(
      "error",
      setImageError.bind(null, true)
    );
    return () => {
      reference.current?.removeEventListener(
        "load",
        setImageLoaded.bind(null, true)
      );
      reference.current?.removeEventListener(
        "error",
        setImageError.bind(null, true)
      );
    };
  }, []);

  return (
    <img
      {...pick(properties, "height", "width", "id", "title", "onClick")}
      alt={toString(alt)}
      className={`${className ?? ""} object-cover transition-all ${
        imageLoaded ? "" : "blur-xl"
      }`}
      decoding={decoding}
      itemProp="image"
      loading={loading}
      ref={imageReference}
      src={fallback && (imageError || !src) ? fallback : src}
    />
  );
};

const LazyImageMemoized = memo(LazyImage);
export { LazyImageMemoized as LazyImage };
