import { memoize, toNumber } from "lodash";

export let timeDiff = 0;

const fetchTimeDiff = memoize(() => {
  if (process.env.JEST_WORKER_ID) return new Promise((resolve) => resolve(0));
  return fetch(`${process.env.NEXT_PUBLIC_BASE_URL_TIME}/hotel/api/v2/now`, {
    method: "GET"
  })
    .then((response) =>
      response.json().then(({ now }) => {
        timeDiff = toNumber(new Date(now)) - Date.now() || 0;
      })
    )
    .catch(() => 0);
});

fetchTimeDiff();
