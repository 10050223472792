import { parseDateTime } from "@internationalized/date";
import { useJwtToken } from "@website/hooks";
import { useTranslation } from "@website/locale";
import { addEventToTracking, USER_TRACKING_EVENTS } from "@website/tracking";
import { DatepickerSelectedDateType } from "@website/ui-old";
import { daysBetweenDates } from "@website/utils";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { PassengersCountType } from "./use-passenger-room-count-tour";

export const useSubmit = (
  originItem: any,
  destinationItem: any,
  date: DatepickerSelectedDateType,
  passengerCount: PassengersCountType,
  calendarLocale: string,
  submitValidationCheck: () => boolean,
  addToHistory: (
    origin: any,
    destination: any,
    startDateTime: string,
    endDateTime: string,
    passengers: string,
    passengerTotalCount: number,
    calendarLocale: string
  ) => void
) => {
  const { t } = useTranslation();
  const router = useRouter();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const { phoneNumber } = useJwtToken();

  const onSubmitClick = useCallback(() => {
    if (submitValidationCheck()) {
      let startDateTime = "";
      let endDateTime = "";
      let startDateObject = null;
      let endDateObject = null;
      let startDate = null;
      let endDate = null;
      if (date && date?.[0] && date?.[1]) {
        startDateTime = date[0]?.dateTime;
        endDateTime = date[1]?.dateTime;

        startDateObject = parseDateTime(startDateTime);
        endDateObject = parseDateTime(endDateTime);

        startDate = `${startDateObject?.year}-${startDateObject?.month
          ?.toString()
          ?.padStart(2, "0")}-${startDateObject?.day
          ?.toString()
          ?.padStart(2, "0")}`;

        endDate = `${endDateObject?.year}-${endDateObject?.month
          ?.toString()
          ?.padStart(2, "0")}-${endDateObject?.day
          ?.toString()
          ?.padStart(2, "0")}`;

        const today = new Date();
        const searchWindow = daysBetweenDates(today, new Date(startDateObject));

        // passengers=adultsCount,childAge,childAge_adultsCount-childAge,childAge
        let passengers = "";
        passengerCount?.map((item, index) => {
          passengers += `${item?.adult?.length || 1}${
            item?.child?.length > 0 ? "," : ""
          }${item?.child?.join(",")}${
            index < passengerCount?.length - 1 ? "_" : ""
          }`;
        });
        const passengerTotalCount = passengerCount?.reduce(
          (accumulator, current) =>
            accumulator + current.adult.length + current.child.length,
          0
        );
        const roomCount = passengerCount?.length;
        addToHistory(
          originItem,
          destinationItem,
          startDateTime,
          endDateTime,
          passengers,
          passengerTotalCount,
          calendarLocale
        );
        if (channel === "jek") {
          const parameters = {
            ...router?.query,
            origin: originItem?.iata_code,
            dest: destinationItem?.iata_code,
            stDate: startDate,
            rtDate: endDate,
            passengers,
            source: "searchBox",
            referrer: "jek"
          };
          window?.webengage?.track("tour-search-phone", {
            phone: phoneNumber,
            channel
          });
          window?.webengage?.track("tour-search-clicked", {
            ...parameters,
            origin: originItem?.title_fa,
            dest: destinationItem?.title_fa,
            channel
          });

          window?.dataLayer?.push({
            event: "tour-search",
            ecommerce: {
              items: [
                {
                  ...parameters,
                  origin: originItem?.city,
                  dest: destinationItem?.city,
                  searchWindow
                }
              ]
            }
          });

          const urlParameters = new URLSearchParams(parameters);
          window.location.href = `${
            process.env.NEXT_PUBLIC_BASE_URL_TOUR_API
          }/listing/v2/init?${urlParameters.toString()}`;
        } else {
          const parameters = {
            origin: originItem?.iata_code,
            dest: destinationItem?.iata_code,
            stDate: startDate,
            rtDate: endDate,
            passengers,
            source: "searchBox",
            referrer: "web"
          };
          window?.webengage?.track("tour-search-clicked", {
            ...parameters,
            channel,
            origin: originItem?.title_fa,
            dest: destinationItem?.title_fa,
            source: "searchBox"
          });
          window?.dataLayer?.push({
            event: "tour-search",
            ecommerce: {
              items: [
                {
                  ...parameters,
                  searchWindow
                }
              ]
            }
          });
          addEventToTracking(USER_TRACKING_EVENTS.GENERAL_SEARCH, {
            product: "tour",
            origin_city: originItem?.title_fa,
            destination_city: destinationItem?.title_fa,
            number_of_passengers: passengerTotalCount,
            departure_date: parameters.stDate,
            return_departure_date: parameters.rtDate,
            number_of_rooms: roomCount,
            search_source: "search-box",
            calendar_type: calendarLocale,
            search_window: searchWindow
          });
          const urlParameters = new URLSearchParams(parameters);
          window.location.href = `${
            process.env.NEXT_PUBLIC_BASE_URL_TOUR_API
          }/listing/v2/init?${urlParameters.toString()}`;
        }
      }
    }
  }, [
    submitValidationCheck,
    date,
    passengerCount,
    addToHistory,
    originItem,
    destinationItem,
    calendarLocale,
    channel,
    router?.query,
    phoneNumber
  ]);

  return {
    onSubmitClick,
    submitText: t("action.search")
  };
};
