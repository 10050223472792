import type { UseQueryResult } from "@tanstack/react-query/src/types";
import { fetcher, useFetcher } from "@website/fetcher";
import { authStore, useSelector } from "@website/store";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useWebengage } from "@website/tracking";
import jwtDecode from "jwt-decode";
import { toString } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useMemo, useRef } from "react";
import {
  AuthResponse,
  B2BProfile,
  DECODED_TOKEN,
  RefreshTokenResponse,
  UserInfo,
  UserInfoUpdateBody
} from "./auth.types";

const baseURL = process.env.NEXT_PUBLIC_BASE_URL_PROFILE;

export const AUTH_ENDPOINTS = {
  CONVERT: `${baseURL}/auth/jek`,
  REFRESH: `${baseURL}/auth/refresh-token`,
  ME: `${baseURL}/user/me`,
  B2B_PROFILE: `${baseURL}/b2b/profile`,
  UPDATE_ME: `${baseURL}/user/me`,
  LOGIN: `${baseURL}/auth/password`
};

export const logout = () => {
  authStore.accessToken.delete();
  authStore.refreshToken.delete();
  authStore.jekToken.delete();
  authStore.delete();
  localStorage.removeItem("auth_store");
  localStorage.removeItem("access-token");
  localStorage.removeItem("refresh-token");
};

export const saveLogin = (data: Partial<AuthResponse>) => {
  if (data?.accessToken) {
    authStore.accessToken.set(data?.accessToken);
    localStorage.setItem("access-token", data?.accessToken);
  }
  if (data?.refreshToken) {
    authStore.refreshToken.set(data?.refreshToken);
    localStorage.setItem("refresh-token", data?.refreshToken);
  }
};

const refreshToken = () =>
  fetcher<RefreshTokenResponse>({
    url: AUTH_ENDPOINTS.REFRESH,
    method: "POST",
    data: { refreshToken: authStore.refreshToken.get() }
  })
    .then(({ data }) => saveLogin(data))
    .catch(logout);
const convert = (jekToken: string) =>
  fetcher<AuthResponse>({
    url: AUTH_ENDPOINTS.CONVERT,
    method: "POST",
    data: { snappToken: jekToken }
  }).then(({ data }) => saveLogin(data));

export const useJwtToken = (): DECODED_TOKEN => {
  const token = useSelector(authStore.accessToken);
  const fallback: DECODED_TOKEN = useMemo(
    () => ({
      analyticsId: "",
      email: "",
      exp: 0,
      iat: 0,
      name: "",
      phoneNumber: "",
      sub: "",
      userPushId: "",
      walletUsername: "",
      customerKey: "",
      profileType: "B2C"
    }),
    []
  );
  return useMemo(() => {
    try {
      return jwtDecode(token) || fallback;
    } catch {
      return fallback;
    }
  }, [fallback, token]);
};

export const updateUserInfo = (data: UserInfoUpdateBody) =>
  fetcher<UserInfo>({
    url: AUTH_ENDPOINTS.UPDATE_ME,
    method: "PATCH",
    data
  })
    .then(({ data }) => data)
    .catch((error) => error.status === 401 && refreshToken);

export const useUserInfo = () => {
  const accessToken = useSelector(authStore.accessToken);
  return useFetcher({
    enabled: Boolean(accessToken),
    queryKey: [AUTH_ENDPOINTS.ME],
    queryFn: () =>
      fetcher<UserInfo>({
        url: AUTH_ENDPOINTS.ME
      })
        .then(({ data }) => data)
        .catch((error) => error.status === 401 && refreshToken)
  }) as UseQueryResult<UserInfo>;
};

export const useB2BProfile = () => {
  const accessToken = useSelector(authStore.accessToken);
  return useFetcher({
    enabled: Boolean(accessToken),
    queryKey: [AUTH_ENDPOINTS.B2B_PROFILE],
    queryFn: () =>
      fetcher<B2BProfile>({
        url: AUTH_ENDPOINTS.B2B_PROFILE
      })
        .then(({ data }) => data)
        .catch((error) => error.status === 401 && refreshToken)
  }) as UseQueryResult<B2BProfile>;
};

export const useProfile = () => {
  const { profileType } = useJwtToken();
  const accessToken = useSelector(authStore.accessToken);

  const url =
    profileType === "B2B" ? AUTH_ENDPOINTS.B2B_PROFILE : AUTH_ENDPOINTS.ME;

  type ProfileType = B2BProfile | UserInfo;

  return useFetcher({
    enabled: Boolean(accessToken),
    queryKey: [url],
    queryFn: () =>
      fetcher<ProfileType>({ url })
        .then(({ data }) => data)
        .catch((error) => error.status === 401 && refreshToken)
  }) as UseQueryResult<ProfileType>;
};

export const useAuth = () => {
  const router = useRouter();
  const { exp, iat } = useJwtToken();
  const { login } = useWebengage();
  const interval = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    const token = toString(router.query?.token);
    const accessToken = toString(router.query?.accessToken);
    const refreshToken = toString(router.query?.refreshToken);
    const b2bToken = toString(router?.query?.b2btoken);
    if (token && token !== authStore.jekToken.get()) {
      authStore.jekToken.set(token);
      convert(token);
    }
    saveLogin({ accessToken: b2bToken || accessToken, refreshToken });
  }, [router.query]);

  useEffect(() => {
    if (interval.current) clearInterval(interval.current);
    if (!exp || !iat) return;
    login();
    const diffTime = (Number(exp) - Number(iat)) * 1000;
    const intervalTime = diffTime > 0 ? diffTime : 5 * 1000;
    interval.current = setInterval(() => {
      if (authStore.refreshToken.get()) {
        refreshToken();
      }
    }, intervalTime);
    return () => clearInterval(interval.current);
  }, [exp, iat, login]);
};
