import { useQuery } from "react-query";
import { SearchService } from "../../../../../services/villa/search";

export const useSearchService = (query: string) => {
  const {
    isLoading,
    data
    // error
  } = useQuery(
    [`${process.env.NEXT_PUBLIC_BASE_URL_HAPI}/search/v2/villa`, query],
    () => SearchService({ q: query }),
    {
      enabled: query.length > 1
    }
  );

  return { isLoading, data: data?.geo };
};
