import { useDebounce } from "@website/hooks";
import { Origin } from "@website/icons";
import { useTranslation } from "@website/locale";
import { useCallback, useMemo, useState } from "react";
import { NoResult } from "../../../..";
import { RegionItemType } from "../../../../../services/villa/search";
import { REGION_NAMES, SUGGESTIONS_LIST } from "../search-villa.constant";
import { useSearchService } from "../services";

export const useDestination = () => {
  const { t } = useTranslation();
  const [destinationQuery, setDestinationQuery] = useState<string>("");
  const [selectedDestination, setSelectedDestination] = useState<string>("");
  const [selectedDestinationItem, setSelectedDestinationItem] = useState(null);
  const query = useDebounce(destinationQuery, 250);

  const { isLoading, data = [] } = useSearchService(query);

  const onChangeDestinationQuery = useCallback((value: string) => {
    setDestinationQuery(value);
  }, []);

  const destinationList = useMemo<Array<RegionItemType>>(
    () => (destinationQuery.length >= 2 ? data || [] : SUGGESTIONS_LIST || []),
    [data, destinationQuery]
  );

  const destinationListNormalized = useMemo(
    () =>
      destinationList.map((item) => ({
        id: `${item.id}`,
        title: item.title,
        slug: item.slug,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        subtitle: t(`homepage.villa.main_search.${REGION_NAMES[item.entity]}`),
        StartIcon: <Origin />
      })),
    [destinationList, t]
  );

  const onSelectDestination = useCallback(
    (id: string) => {
      setSelectedDestination(id);
      const destinationItem = destinationList.find(
        (destination: any) => `${destination.id}` === `${id}`
      );
      setDestinationQuery("");
      setSelectedDestinationItem(destinationItem);
    },
    [destinationList]
  );

  const onChangeDestination = useCallback((item: any) => {
    setSelectedDestination(item?.id || "");
    setDestinationQuery("");
    setSelectedDestinationItem(item);
  }, []);

  return {
    selectedDestinationItem,
    selectedDestinationText: selectedDestinationItem?.title || "",
    destinationQuery,
    selectedDestination,
    onChangeDestination,
    onSelectDestination,
    onChangeDestinationQuery,
    destinationList,
    destinationLoading: isLoading,
    destinationListNormalized,
    DestinationNoResult:
      destinationQuery?.length >= 2 &&
      !isLoading &&
      data &&
      data.length === 0 ? (
        <NoResult query={destinationQuery} />
      ) : undefined,
    destinationLabel: t("homepage.villa.main_search.destination")
  };
};
