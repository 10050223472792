import { deepCompare } from "@website/utils";
import { memo } from "react";
import loveEyesEmoji from "./assets/love-eyes-emoji.svg";
import pokerFaceEmoji from "./assets/poker-face-emoji.svg";
import sadEmoji from "./assets/sad-emoji.svg";
import { ReserveCommentAvatarPropertiesType } from "./reserve-comment-avatar.types";

const Component = ({ rate, className }: ReserveCommentAvatarPropertiesType) => (
  <img
    alt={
      rate < 2
        ? "sad-emoji"
        : rate >= 2 && rate < 4
          ? "poker-emoji"
          : "love-eyes-emoji"
    }
    className={className}
    height={32}
    loading="lazy"
    src={
      rate < 2
        ? sadEmoji?.src
        : rate >= 2 && rate < 4
          ? pokerFaceEmoji?.src
          : loveEyesEmoji?.src
    }
    width={32}
  />
);

export const ReserveCommentAvatar = memo(Component, deepCompare);
