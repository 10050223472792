import { debounce, omit } from "lodash";
import { RefObject, useEffect, useRef, useState } from "react";
import { UseIsVisibleOptionsType, UseIsVisibleType } from "./is-visible.types";

export const isVisible = (
  element?: Element | null,
  options?: UseIsVisibleOptionsType
) => {
  try {
    const observer = new IntersectionObserver(
      ([entry]) => options?.onChange?.(entry.isIntersecting),
      omit(options, "onChange") ?? {}
    );
    element && observer?.observe(element);
    return {
      unobserve: () => {
        element && observer?.unobserve(element);
      }
    };
  } catch {
    return {
      unobserve: () => undefined
    };
  }
};

export const useIsVisible: UseIsVisibleType = (options) => {
  const reference = useRef<HTMLDivElement>();
  const _unobserve = useRef(() => {});
  const [inView, setInView] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    debounce(() => {
      _unobserve.current?.();
      const { unobserve } = isVisible(reference.current, {
        ...options,
        onChange: (inView: boolean) => {
          setInView(inView);
          options?.onChange?.(inView);
        }
      });
      _unobserve.current = unobserve;
      return unobserve;
    }, 50),
    [options]
  );

  return {
    reference: reference as RefObject<HTMLDivElement>,
    inView
  };
};
