import { useTranslation } from "@website/locale";
import { numberConvert } from "@website/utils";
import { useMemo } from "react";
import {
  DatepickerSplitPropertiesType,
  DatepickerSplitSelectedDateObjectType
} from "../datepicker-split.types";
import { getDaysInMonth, getToday } from "../datepicker-split.utils";

export const useList = (
  properties: DatepickerSplitPropertiesType,
  selectedDateObject: DatepickerSplitSelectedDateObjectType
) => {
  const {
    calendarLocale = "persian",
    numberOfYears = 50,
    numberOfYearsAfterNow = 0,
    disabledDateMode
  } = properties;
  const { t, locale } = useTranslation();
  const todayDate = getToday(calendarLocale);

  const yearsList = useMemo(() => {
    if (disabledDateMode === "past") {
      return Array.from({ length: numberOfYears }, (value, index) => ({
        value: `${todayDate.year + numberOfYearsAfterNow - index}`,
        title: numberConvert(todayDate.year + numberOfYearsAfterNow - index, {
          numberOnly: true,
          locale
        })
      })).filter((year) => Number(year.value) >= todayDate?.year);
    }
    return Array.from({ length: numberOfYears }, (value, index) => ({
      value: `${todayDate.year + numberOfYearsAfterNow - index}`,
      title: numberConvert(todayDate.year + numberOfYearsAfterNow - index, {
        numberOnly: true,
        locale
      })
    }));
  }, [
    disabledDateMode,
    numberOfYears,
    todayDate.year,
    numberOfYearsAfterNow,
    locale
  ]);

  const monthsList = useMemo(
    () => [
      {
        value: "1",
        title: `${numberConvert("01", {
          numberOnly: true,
          locale
        })} - ${t<string>(`calendar.${calendarLocale}.monthNames.one`)}`
      },
      {
        value: "2",
        title: `${numberConvert("02", {
          numberOnly: true,
          locale
        })} - ${t<string>(`calendar.${calendarLocale}.monthNames.two`)}`
      },
      {
        value: "3",
        title: `${numberConvert("03", {
          numberOnly: true,
          locale
        })} - ${t<string>(`calendar.${calendarLocale}.monthNames.three`)}`
      },
      {
        value: "4",
        title: `${numberConvert("04", {
          numberOnly: true,
          locale
        })} - ${t<string>(`calendar.${calendarLocale}.monthNames.four`)}`
      },
      {
        value: "5",
        title: `${numberConvert("05", {
          numberOnly: true,
          locale
        })} - ${t<string>(`calendar.${calendarLocale}.monthNames.five`)}`
      },
      {
        value: "6",
        title: `${numberConvert("06", {
          numberOnly: true,
          locale
        })} - ${t<string>(`calendar.${calendarLocale}.monthNames.six`)}`
      },
      {
        value: "7",
        title: `${numberConvert("07", {
          numberOnly: true,
          locale
        })} - ${t<string>(`calendar.${calendarLocale}.monthNames.seven`)}`
      },
      {
        value: "8",
        title: `${numberConvert("08", {
          numberOnly: true,
          locale
        })} - ${t<string>(`calendar.${calendarLocale}.monthNames.eight`)}`
      },
      {
        value: "9",
        title: `${numberConvert("09", {
          numberOnly: true,
          locale
        })} - ${t<string>(`calendar.${calendarLocale}.monthNames.nine`)}`
      },
      {
        value: "10",
        title: `${numberConvert("10", {
          numberOnly: true,
          locale
        })} - ${t<string>(`calendar.${calendarLocale}.monthNames.ten`)}`
      },
      {
        value: "11",
        title: `${numberConvert("11", {
          numberOnly: true,
          locale
        })} - ${t<string>(`calendar.${calendarLocale}.monthNames.eleven`)}`
      },
      {
        value: "12",
        title: `${numberConvert("12", {
          numberOnly: true,
          locale
        })} - ${t<string>(`calendar.${calendarLocale}.monthNames.twelve`)}`
      }
    ],
    [locale, t, calendarLocale]
  );

  const daysList = useMemo(() => {
    const { year, month } = selectedDateObject;
    const daysInMonth = month
      ? getDaysInMonth(calendarLocale, Number(year) || 1, Number(month))
      : 29;
    return Array.from({ length: daysInMonth }, (value, index) => ({
      value: `${index + 1}`,
      title: numberConvert(index + 1, {
        numberOnly: true,
        locale
      })
    }));
  }, [selectedDateObject, calendarLocale, locale]);
  return {
    yearsList,
    monthsList,
    daysList
  };
};
