export const home_ui = {
  my_trips: {
    title: "سفرهای من",
    tabs: {
      current: "جاری",
      past: "گذشته"
    },
    state: {
      hotel: {
        payment_verified: "سفارش موفق",
        payment_notice_sent: "در انتظار پرداخت",
        payment_on: "درحال پرداخت",
        payment_failed: "پرداخت ناموفق",
        notassigned: "نامشخص",
        awaiting_clearance: "در انتظار ترخیص",
        requested: "در حال کنسلی",
        accepted_by_user: "در حال کنسلی",
        rejected_by_user: "کنسل توسط کاربر",
        rejected_by_operator: "کنسل توسط اپراتور",
        cs_update_fees: "بروزرسانی قیمت",
        done: "کنسل شده",
        null: "",
        user_confirmed_info: "تایید توسط کاربر",
        draft: "ثبت موقت"
      },
      inter_hotel: {
        payment_on: "در انتظار پرداخت",
        payment_verified: "سفارش موفق",
        confirmed: "تایید شده",
        failed: "سفارش ناموفق",
        canceled: "کنسل شده",
        payment_refunded: "ناقص",
        draft: "در انتظار تکمیل اطلاعات",
        payment_failed: "پرداخت ناموفق",
        notassigned: "نامشخص"
      },
      inter_flight: {
        SUCCESSFUL: "سفارش موفق",
        FAILED: "صادر نشده",
        FAILED_WITH_REFERENCE: "نامشخص",
        CANCELLED: "کنسل شده",
        notassigned: "نامشخص"
      },
      dom_flight: {
        notassigned: "نامشخص",
        success: "سفارش موفق",
        issue_failed: "سفارش ناموفق",
        issue_failed_with_reference: "صدور نامشخص",
        cancelled: "کنسل شده"
      },
      bus: {
        SUCCESS: "موفق",
        FAILED: "ناموفق",
        PENDING: " در انتظار پاسخ پروایدر",
        CANCELLED: " کنسل شده(سمت تعاونی)",
        REFUNDING: " کنسل شده(در حال ریفاند)",
        REFUNDED: " کنسل شده(ریفاند شده)",
        notassigned: "نامشخص"
      },
      train: {
        CREATED: "ایجاد شده",
        PAYED: "پرداخت شده",
        FAILED: "ناموفق",
        ISSUED: "موفق",
        notassigned: "نامشخص"
      }
    },
    card: {
      unit: "تومان",
      tracking_code: "کد پیگیری",
      to: "به",
      to_date: "تا",
      night: "شب",
      departure_from: "حرکت از",
      one_way: "یک‌طرفه",
      round_trip: "رفت و برگشت",
      away: "رفت",
      return: "برگشت",
      flight: "پرواز",
      bus: "اتوبوس",
      train: "قطار",
      cancelation_reserve: "کنسلی رزرو",
      show_details: "مشاهده جزییات",
      sidesheet: {
        voucher_download: "دانلود ووچر"
      },
      star: "ستاره",
      reserver: "رزروکننده",
      phonenumber: "شماره موبایل",
      reserve_code: "کد رزرو",
      arrival_and_derature: "تاریخ ورود و خروج",
      staying_time: "مدت اقامت",
      number_of_room: "تعداد اتاق",
      total_reserved_capacity: "مجموع ظرفیت رزرو شده",
      total_price: "قیمت کل",
      room: "اتاق",
      adult: "بزرگسال",
      child: "کودک",
      and: "و",
      passenger: "مسافر",
      supervisor: "سرپرست",
      order_time: "زمان سفارش",
      inter_hotel: {
        title: "جزئیات رزرو هتل خارجی",
        no_title: "بدون عنوان"
      },
      domestic_hotel: {
        title: "جزئیات رزرو هتل داخلی"
      },
      cancelation_modal: {
        cancelation_reserve: "کنسلی رزرو",
        detail_reserve: "جزییات رزرو",
        cancelation: "کنسلی",
        detail: "جزییات",
        line_1_sec_1: "آیا از کنسل کردن رزرو",
        line_1_sec_2: "اطمینان دارید؟",
        line_2:
          "توجه داشته باشید که مبلغ پرداختی، با کسر جریمه، طبق قوانین بانکی کشور، طی ۷۲ ساعت به حساب بانکی که با آن پرداخت کرده‌اید واریز می‌شود.",
        submit: "تایید {action} رزرو",
        cancel: "انصراف"
      },
      terms: {
        section_1: "تایید کنسلی به معنای پذیرش",
        section_2: "قوانین و مقررات کنسلی {service} اسنپ‌تریپ",
        section_3: "می باشد."
      },
      tour: {
        line_1:
          "شرایط کنسلی تورها با توجه به مقررات مربوط به مسیرها و آژانس های تامین کننده، متفاوت است.",
        line_2:
          "بر اساس این شرایط، مبلغ جریمه کنسلی ممکن است از صفر تا صد درصد مبلغ تور باشد و مابقی مبلغ طی ۷۲ ساعت به شما بازگردانده خواهد شد. لازم به ذکر است که این شرایط، توسط آژانس های تامین کننده تور مشخص شده اند.",
        line_3:
          "جهت بررسی شرایط کنسلی تور مد نظر خود، میتوانید با شماره پشتیبانی ۰۲۱۹۲۰۰۰۱۲۳ تماس بگیرید.",
        support: "تماس با پشتیبانی (۱۲۳ ۰۰۰ ۹۲ ۰۲۱)",
        call_support: "تماس با پشتیبانی"
      },
      cancel_routes: "درخواست {action} کدام {service} را دارید؟"
    },
    services: {
      domestic_hotels: "هتل داخلی",
      inter_hotel: "هتل خارجی",
      domestic_flights: "پرواز داخلی",
      inter_flights: "پرواز خارجی",
      bus: "اتوبوس",
      stays: "ویلا و  سوییت",
      train: "قطار",
      tour: "تور"
    },
    general: {
      services: {
        hotels: "هتل",
        flights: "پرواز",
        domestic_hotels: "هتل داخلی",
        inter_hotels: "هتل خارجی",
        domestic_flights: "پرواز داخلی",
        inter_flights: "پرواز خارجی",
        bus: "اتوبوس",
        bus_domestic: "اتوبوس داخلی",
        bus_international: "اتوبوس خارجی",
        stays: "ویلا",
        tour: "تور",
        train: "قطار"
      }
    },
    messages: {
      general_error: "خطایی رخ داده است, دوباره تلاش کنید.",
      deleted_reserve: "حذف رزرو با موفقیت انجام شد.",
      cancelableـreserve: "رزرو غیرقایل کنسل است. ",
      cancellation_state_requested: "در انتظار درخواست کنسلی"
    }
  },
  no_trips: {
    title: "شما سفری در پیش رو ندارید.",
    description: "برای شروع، بلیط سفر و اقامتگاه خود را رزرو کنید.",
    start_trip: "شروع سفر"
  },
  b2b_landing: {
    hero: {
      title: "راهکارهای سازمانی اسنپ‌تریپ",
      description:
        "با ثبت نام به عنوان نماینده آژانس یا سازمان از خدمات و مزایای ویژه همکاران تجاری بهره‌مند خواهید شد، همه خدمات سفر از خرید بلیت هواپیما، قطار و اتوبوس تا رزرو هتل را با تخفیف و شرایط ویژه دریافت خواهید کرد و می‌توانید از همه این مراحل گزارش‌گیری دقیق و منظم داشته باشید."
    },
    cooperation_request: {
      success:
        "درخواست همکاری شما ثبت شد. همکاران ما به زودی با شما تماس می‌گیرند.",
      failed: "خطایی رخ داده دوباره تلاش کنید."
    }
  }
};
