import { useViewPort } from "@website/hooks";
import {
  Ballon,
  Bus,
  Flight,
  FlightInternational,
  Hotel,
  Train,
  Villa
} from "@website/icons";
import { useTranslation } from "@website/locale";
import { EnvironmentChannelType } from "@website/store";
import { debounce, throttle } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSettings } from "../../providers";
import { default as busDarkImageSnappir } from "../../public/images/bus-dark-snappir.webp";
import { default as busDarkImage } from "../../public/images/bus-dark.webp";
import { default as busImageSnappir } from "../../public/images/bus-snappir.webp";
import { default as busImage } from "../../public/images/bus.webp";
import { default as flightDarkImageSnappir } from "../../public/images/flight-dark-snappir.webp";
import { default as flightDarkImage } from "../../public/images/flight-dark.webp";
import { default as flightImageSnappir } from "../../public/images/flight-snappir.webp";
import { default as flightImage } from "../../public/images/flight.webp";
import { default as hotelDarkImageSnappir } from "../../public/images/hotel-dark-snappir.webp";
import { default as hotelDarkImage } from "../../public/images/hotel-dark.webp";
import { default as hotelImageSnappir } from "../../public/images/hotel-snappir.webp";
import { default as hotelImage } from "../../public/images/hotel.webp";
import { default as interFlightDarkImageSnappir } from "../../public/images/inter-flight-dark-snappir.webp";
import { default as interFlightDarkImage } from "../../public/images/inter-flight-dark.webp";
import { default as interFlightImageSnappir } from "../../public/images/inter-flight-snappir.webp";
import { default as interFlightImage } from "../../public/images/inter-flight.webp";
import { default as interHotelDarkImageSnappir } from "../../public/images/inter-hotel-dark-snappir.webp";
import { default as interHotelDarkImage } from "../../public/images/inter-hotel-dark.webp";
import { default as interHotelImageSnappir } from "../../public/images/inter-hotel-snappir.webp";
import { default as interHotelImage } from "../../public/images/inter-hotel.webp";
import { default as tourDarkImageSnappir } from "../../public/images/tour-dark-snappir.webp";
import { default as tourDarkImage } from "../../public/images/tour-dark.webp";
import { default as tourImageSnappir } from "../../public/images/tour-snappir.webp";
import { default as tourImage } from "../../public/images/tour.webp";
import { default as trainDarkImageSnappir } from "../../public/images/train-dark-snappir.webp";
import { default as trainDarkImage } from "../../public/images/train-dark.webp";
import { default as trainImageSnappir } from "../../public/images/train-snappir.webp";
import { default as trainImage } from "../../public/images/train.webp";
import { default as villaDarkImageSnappir } from "../../public/images/villa-dark-snappir.webp";
import { default as villaDarkImage } from "../../public/images/villa-dark.webp";
import { default as villaImageSnappir } from "../../public/images/villa-snappir.webp";
import { default as villaImage } from "../../public/images/villa.webp";
import { RouteButtonSizeType } from "./components/route-button";
import { SearchBus } from "./components/search-bus";
import { SearchBusInternational } from "./components/search-bus-international";
import { SearchFlights } from "./components/search-flights";
import { SearchHotel } from "./components/search-hotel";
import { SearchInterFlights } from "./components/search-inter-flights";
import { SearchInterHotel } from "./components/search-international-hotel";
import { SearchTour } from "./components/search-tour";
import { SearchTrain } from "./components/search-train";
import { SearchVilla } from "./components/search-villa";
import { SearchVisa } from "./components/search-visa";
import { SearchPropertiesType } from "./search.types";

const channel = process.env[
  "NEXT_PUBLIC_CHANNEL_TYPE"
] as EnvironmentChannelType;

const adsType = process.env["NEXT_PUBLIC_ADS_TYPE"];

const images = {
  "/": {
    alt: "hotel",
    webp: {
      light: hotelImage.src,
      dark: hotelDarkImage.src
    },
    svg: {
      light: hotelImage.src,
      dark: hotelDarkImage.src
    }
  },
  "/international-hotel": {
    alt: "international-hotel",
    webp: {
      light: interHotelImage.src,
      dark: interHotelDarkImage.src
    },
    svg: {
      light: interHotelImage.src,
      dark: interHotelDarkImage.src
    }
  },
  "/flights": {
    alt: "airplane",
    webp: {
      light: flightImage.src,
      dark: flightDarkImage.src
    },
    svg: {
      light: flightImage.src,
      dark: flightDarkImage.src
    }
  },
  [channel === "jek" ? "/interflights" : "/inter-flights"]: {
    alt: "airplane",
    webp: {
      light: interFlightImage.src,
      dark: interFlightDarkImage.src
    },
    svg: {
      light: interFlightImage.src,
      dark: interFlightDarkImage.src
    }
  },
  "/home-visa": {
    alt: "airplane",
    webp: {
      light: interFlightImage.src,
      dark: interFlightDarkImage.src
    },
    svg: {
      light: interFlightImage.src,
      dark: interFlightDarkImage.src
    }
  },
  "/train": {
    alt: "train",
    webp: {
      light: trainImage.src,
      dark: trainDarkImage.src
    },
    svg: {
      light: trainImage.src,
      dark: trainDarkImage.src
    }
  },
  "/bus": {
    alt: "bus",
    webp: {
      light: busImage.src,
      dark: busDarkImage.src
    },
    svg: {
      light: busImage.src,
      dark: busDarkImage.src
    }
  },
  "/bus-international": {
    alt: "bus",
    webp: {
      light: busImage.src,
      dark: busDarkImage.src
    },
    svg: {
      light: busImage.src,
      dark: busDarkImage.src
    }
  },
  "/villa": {
    alt: "house",
    webp: {
      light: villaImage.src,
      dark: villaDarkImage.src
    },
    svg: {
      light: villaImage.src,
      dark: villaDarkImage.src
    }
  },
  "/tour": {
    alt: "tour",
    webp: {
      light: tourImage.src,
      dark: tourDarkImage.src
    },
    svg: {
      light: tourImage.src,
      dark: tourDarkImage.src
    }
  }
};

const imagesSnappir = {
  "/": {
    alt: "hotel",
    webp: {
      light: hotelImageSnappir.src,
      dark: hotelDarkImageSnappir.src
    },
    svg: {
      light: hotelImageSnappir.src,
      dark: hotelDarkImageSnappir.src
    }
  },
  "/international-hotel": {
    alt: "international-hotel",
    webp: {
      light: interHotelImageSnappir.src,
      dark: interHotelDarkImageSnappir.src
    },
    svg: {
      light: interHotelImageSnappir.src,
      dark: interHotelDarkImageSnappir.src
    }
  },
  "/flights": {
    alt: "airplane",
    webp: {
      light: flightImageSnappir.src,
      dark: flightDarkImageSnappir.src
    },
    svg: {
      light: flightImageSnappir.src,
      dark: flightDarkImageSnappir.src
    }
  },
  "/inter-flights": {
    alt: "airplane",
    webp: {
      light: interFlightImageSnappir.src,
      dark: interFlightDarkImageSnappir.src
    },
    svg: {
      light: interFlightImageSnappir.src,
      dark: interFlightDarkImageSnappir.src
    }
  },
  "/home-visa": {
    alt: "airplane",
    webp: {
      light: interFlightImageSnappir.src,
      dark: interFlightDarkImageSnappir.src
    },
    svg: {
      light: interFlightImageSnappir.src,
      dark: interFlightDarkImageSnappir.src
    }
  },
  "/train": {
    alt: "train",
    webp: {
      light: trainImageSnappir.src,
      dark: trainDarkImageSnappir.src
    },
    svg: {
      light: trainImageSnappir.src,
      dark: trainDarkImageSnappir.src
    }
  },
  "/bus": {
    alt: "bus",
    webp: {
      light: busImageSnappir.src,
      dark: busDarkImageSnappir.src
    },
    svg: {
      light: busImageSnappir.src,
      dark: busDarkImageSnappir.src
    }
  },
  "/bus-international": {
    alt: "bus",
    webp: {
      light: busImageSnappir.src,
      dark: busDarkImageSnappir.src
    },
    svg: {
      light: busImageSnappir.src,
      dark: busDarkImageSnappir.src
    }
  },
  "/villa": {
    alt: "house",
    webp: {
      light: villaImageSnappir.src,
      dark: villaDarkImageSnappir.src
    },
    svg: {
      light: villaImageSnappir.src,
      dark: villaDarkImageSnappir.src
    }
  },
  "/tour": {
    alt: "tour",
    webp: {
      light: tourImageSnappir.src,
      dark: tourDarkImageSnappir.src
    },
    svg: {
      light: tourImageSnappir.src,
      dark: tourDarkImageSnappir.src
    }
  }
};

export const useSearch = (properties: SearchPropertiesType) => {
  const [routeChanged, setRouteChanged] = useState<boolean>(false);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const { theme } = useSettings();
  const { t } = useTranslation();
  const router = useRouter();
  const { isMobile } = useViewPort();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;

  const query = new URLSearchParams(
    router.query as Record<string, string>
  ).toString();
  const { pathname } = router;
  const reference = useRef(pathname);

  useEffect(() => {
    const handleBeforeChange = () => {
      if (reference.current !== pathname) {
        setRouteChanged(true);
      }
    };
    const handleChangeComplete = debounce(() => {
      if (
        reference.current !== pathname ||
        (reference.current === "/" && pathname === "/")
      ) {
        setRouteChanged(false);
        reference.current = pathname;
      }
    }, 300);

    if (!isMobile) {
      router.events.on("beforeHistoryChange", handleBeforeChange);
      router.events.on("routeChangeComplete", handleChangeComplete);
    }
    return () => {
      if (!isMobile) {
        router.events.off("beforeHistoryChange", handleBeforeChange);
        router.events.off("routeChangeComplete", handleChangeComplete);
      }
    };
  }, [pathname, isMobile, router.events]);

  useEffect(() => {
    const onScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    const onScrollThrottle = throttle(onScroll, 100);

    if (typeof window !== "undefined" && isMobile) {
      window.addEventListener("scroll", onScrollThrottle);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", onScrollThrottle);
        setScrolled(false);
      }
    };
  }, [isMobile]);

  const routesCollapsed = useMemo(() => {
    if (channel === "jek") {
      return [
        {
          title: t("homepage.services.hotels"),
          href: `/${query ? `?${query}` : ""}`,
          Icon: <Hotel />,
          selected:
            router.pathname === `/` ||
            router.pathname === `/international-hotel`,
          size: scrolled
            ? ("small" as RouteButtonSizeType)
            : ("large" as RouteButtonSizeType)
        },
        {
          title: t("homepage.services.flights"),
          href: `/flights${query ? `?${query}` : ""}`,
          Icon: <Flight />,
          selected:
            router.pathname === "/flights" ||
            router.pathname === "/interflights" ||
            router.pathname === "/home-visa",
          size: scrolled
            ? ("small" as RouteButtonSizeType)
            : ("large" as RouteButtonSizeType)
        },
        {
          title: t("homepage.services.bus"),
          href: `/bus${query ? `?${query}` : ""}`,
          Icon: <Bus />,
          selected:
            router.pathname === "/bus" ||
            router.pathname === "/bus-international",
          size: scrolled
            ? ("small" as RouteButtonSizeType)
            : ("large" as RouteButtonSizeType)
        },
        {
          title: t("homepage.services.train"),
          href: `/train${query ? `?${query}` : ""}`,
          Icon: <Train />,
          selected: router.pathname === "/train",
          size: scrolled
            ? ("small" as RouteButtonSizeType)
            : ("large" as RouteButtonSizeType)
        },

        {
          title: t("homepage.services.stays"),
          href: `/villa${query ? `?${query}` : ""}`,
          Icon: <Villa />,
          selected: router.pathname === "/villa",
          size: scrolled
            ? ("small" as RouteButtonSizeType)
            : ("large" as RouteButtonSizeType)
        },
        {
          title: t("homepage.services.tour"),
          href: `/tour${query ? `?${query}` : ""}`,
          Icon: <Ballon />,
          selected: router.pathname === "/tour",
          size: scrolled
            ? ("small" as RouteButtonSizeType)
            : ("large" as RouteButtonSizeType)
        }
      ];
    }
    if (channel === "ads" || channel === "snapp-ir") {
      return [
        {
          title:
            channel === "snapp-ir"
              ? t("homepage.services.domestic_hotels")
              : adsType === "intl-hotel"
                ? t("homepage.services.inter_hotels")
                : t("homepage.services.hotels"),
          href: `/${query ? `?${query}` : ""}`,
          Icon: <Hotel />,
          selected: router.pathname === `/`,
          size: scrolled
            ? ("small" as RouteButtonSizeType)
            : ("large" as RouteButtonSizeType)
        }
      ];
    }
    return [
      {
        title: t("homepage.services.hotels"),
        href: `/${query ? `?${query}` : ""}`,
        Icon: <Hotel />,
        selected:
          router.pathname === `/` || router.pathname === "/international-hotel",
        size: scrolled
          ? ("small" as RouteButtonSizeType)
          : ("large" as RouteButtonSizeType)
      },
      {
        title: t("homepage.services.flights"),
        href: `/flights${query ? `?${query}` : ""}`,
        Icon: <Flight />,
        selected:
          router.pathname === "/flights" ||
          router.pathname === "/inter-flights",
        size: scrolled
          ? ("small" as RouteButtonSizeType)
          : ("large" as RouteButtonSizeType)
      },
      ...(channel === "b2b"
        ? []
        : [
            {
              title: t("homepage.services.bus"),
              href: `/bus${query ? `?${query}` : ""}`,
              Icon: <Bus />,
              selected:
                router.pathname === "/bus" ||
                router.pathname === "/bus-international",
              size: scrolled
                ? ("small" as RouteButtonSizeType)
                : ("large" as RouteButtonSizeType)
            },
            {
              title: t("homepage.services.stays"),
              href: `/villa${query ? `?${query}` : ""}`,
              Icon: <Villa />,
              selected: router.pathname === "/villa",
              size: scrolled
                ? ("small" as RouteButtonSizeType)
                : ("large" as RouteButtonSizeType)
            }
          ]),
      {
        title: t("homepage.services.tour"),
        href: `/tour${query ? `?${query}` : ""}`,
        Icon: <Ballon />,
        selected: router.pathname === "/tour",
        size: scrolled
          ? ("small" as RouteButtonSizeType)
          : ("large" as RouteButtonSizeType)
      }
    ];
  }, [channel, query, router.pathname, scrolled, t]);

  const routes = useMemo(() => {
    if (channel === "ads" || channel === "snapp-ir") {
      return [
        {
          title:
            channel === "snapp-ir"
              ? t("homepage.services.domestic_hotels")
              : adsType === "intl-hotel"
                ? t("homepage.services.inter_hotels")
                : t("homepage.services.hotels"),
          href: `/${query ? `?${query}` : ""}`,
          Icon: <Hotel />,
          selected: router.pathname === `/`,
          size: scrolled
            ? ("small" as RouteButtonSizeType)
            : ("large" as RouteButtonSizeType)
        }
      ];
    }
    return [
      {
        title: t("homepage.services.hotels"),
        href: `/${query ? `?${query}` : ""}`,
        Icon: <Hotel />,
        selected:
          router.pathname === "/" || router.pathname === "/international-hotel",
        size: scrolled
          ? ("small" as RouteButtonSizeType)
          : ("large" as RouteButtonSizeType)
      },
      {
        title: t("homepage.services.domestic_flights"),
        href: `/flights${query ? `?${query}` : ""}`,
        Icon: <Flight />,
        selected: router.pathname === "/flights",
        size: scrolled
          ? ("small" as RouteButtonSizeType)
          : ("large" as RouteButtonSizeType)
      },
      {
        title: t("homepage.services.inter_flights"),
        href: `/inter-flights${query ? `?${query}` : ""}`,
        Icon: <FlightInternational />,
        selected: router.pathname === `/inter-flights`,
        size: scrolled
          ? ("small" as RouteButtonSizeType)
          : ("large" as RouteButtonSizeType)
      },
      ...(channel === "b2b"
        ? []
        : [
            {
              title: t("homepage.services.bus"),
              href: `/bus${query ? `?${query}` : ""}`,
              Icon: <Bus />,
              selected:
                router.pathname === "/bus" ||
                router.pathname === "/bus-international",
              size: scrolled
                ? ("small" as RouteButtonSizeType)
                : ("large" as RouteButtonSizeType)
            },
            {
              title: t("homepage.services.stays"),
              href: `/villa${query ? `?${query}` : ""}`,
              Icon: <Villa />,
              selected: router.pathname === "/villa",
              size: scrolled
                ? ("small" as RouteButtonSizeType)
                : ("large" as RouteButtonSizeType)
            }
          ]),
      {
        title: t("homepage.services.tour"),
        href: `/tour${query ? `?${query}` : ""}`,
        Icon: <Ballon />,
        selected: router.pathname === "/tour",
        size: scrolled
          ? ("small" as RouteButtonSizeType)
          : ("large" as RouteButtonSizeType)
      }
    ];
  }, [channel, query, router.pathname, scrolled, t]);

  const panels = {
    "/":
      process.env["NEXT_PUBLIC_ADS_TYPE"] === "intl-hotel" ? (
        <SearchInterHotel />
      ) : (
        <SearchHotel />
      ),
    "/international-hotel": <SearchInterHotel />,
    "/flights": <SearchFlights {...properties} />,
    [channel === "jek" ? "/interflights" : "/inter-flights"]: (
      <SearchInterFlights />
    ),
    "/bus": <SearchBus />,
    "/bus-international": <SearchBusInternational />,
    "/villa": <SearchVilla />,
    "/tour": <SearchTour />,
    "/train": <SearchTrain {...properties} />,
    "/home-visa": <SearchVisa />
  };

  return {
    scrolled,
    routeChanged,
    sourceProperties: {
      srcSet:
        channel === "snapp-ir"
          ? imagesSnappir[router?.pathname || "/"].webp[theme]
          : images[router?.pathname || "/"].webp[theme],
      type: "image/webp"
    },
    imageProperties: {
      alt:
        channel === "snapp-ir"
          ? imagesSnappir[router?.pathname || "/"].alt
          : images[router?.pathname || "/"].alt,
      src:
        channel === "snapp-ir"
          ? imagesSnappir[router?.pathname || "/"].svg[theme]
          : images[router?.pathname || "/"].svg[theme],
      width: 1200,
      height: 208
    },
    routes: channel === "jek" || isMobile ? routesCollapsed : routes,
    panel: panels[router?.pathname || "/"]
  };
};
