import { useViewPort } from "@website/hooks";
import { CarouselV2 } from "@website/ui";
import { Skeleton } from "@website/ui-old";
import { FC, memo, useState } from "react";
import styles from "./banner-medium.module.scss";
import { BannerMediumPropertiesType } from "./banner-medium.types";
import { BannerMediumItem } from "./components";

const BannerMedium: FC<BannerMediumPropertiesType> = (properties) => {
  const { list, isLoading = false, className } = properties;
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { isMobile } = useViewPort();

  const renderCarousel = () => {
    if (isLoading) {
      return (
        <div className={styles["skeleton"]}>
          {(isMobile ? [1] : [1, 2, 3]).map((item) => (
            <Skeleton height={200} key={item} />
          ))}
        </div>
      );
    }
    if (Array.isArray(list) && list.length > 0) {
      return (
        <CarouselV2
          itemClass={styles["item"]}
          key={`BannerMedium-${list[selectedTab]?.bannerId}`}
          keyCarousel={`BannerMedium-${list[selectedTab]?.bannerId}`}
          list={list.map((item, index) => (
            <BannerMediumItem
              key={index++}
              {...{
                ...item,
                id: index,
                onClick: () => setSelectedTab(index)
              }}
            />
          ))}
          mode="free"
          options={{
            slides: {
              perView: "auto",
              spacing: 16
            }
          }}
        />
      );
    }

    return null;
  };

  return (
    <article className={`${className} ${styles["container"]}`}>
      {renderCarousel()}
    </article>
  );
};

const BannerMediumMemoized = memo(BannerMedium);
export { BannerMediumMemoized as BannerMedium };
