// eslint-disable-next-line @nx/enforce-module-boundaries
import { useJwtToken } from "@website/hooks";
import { toString } from "lodash";
import { useCallback, useMemo } from "react";
import { AddEventToWebengageType, WebengageLoginType } from "./tracking.types";

const mapEventPrefix: Record<string, string> = {
  b2b: "b2b"
};

export const addEventToWebengage: AddEventToWebengageType = (event, data) => {
  const mappedPrefix = mapEventPrefix[process.env.NEXT_PUBLIC_CHANNEL_TYPE];

  if (!window.webengage) {
    return setTimeout(() => addEventToWebengage(event, data), 1000);
  }

  window.webengage.track(mappedPrefix ? `${mappedPrefix}-${event}` : event, {
    ...data,
    channel: process.env.NEXT_PUBLIC_CHANNEL_TYPE
  });
};

export const useWebengage: WebengageLoginType = () => {
  const { userPushId, phoneNumber, email, name } = useJwtToken();

  const login = useCallback(
    (guestUserPhone?: string | number) => {
      const phone = toString(phoneNumber || guestUserPhone);
      if (!window.webengage) {
        setTimeout(login, 1000);
        return;
      }
      userPushId && window.webengage.user.login(userPushId);
      email && window.webengage.user.setAttribute("we_email", email);
      phone && window.webengage.user.setAttribute("we_phone", phone);
      name && window.webengage.user.setAttribute("we_first_name", name);
      phone &&
        window.webengage.user.setAttribute(
          "random-usernumber",
          Number(phone.slice(-1))
        );
    },
    [email, name, phoneNumber, userPushId]
  );

  return useMemo(() => ({ login }), [login]);
};
