import Script from "next/script";

export const GoogleTagManager = ({ licence }: { licence: string }) => (
  <Script
    dangerouslySetInnerHTML={{
      __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window, document, 'script', 'dataLayer', '${licence}')`
    }}
    id="gtm_script"
    strategy="lazyOnload"
  />
);
