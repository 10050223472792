export const flight_international = {
  result_page: {
    header_title: "بلیط هواپیما",
    filtersTitle: "‌‌فیلتر ها {countString}",
    show_more: "مشاهده {count} نتیجه دیگر",
    sort: "مرتب سازی",
    results_header: {
      flights: "پرواز",
      to: "به",
      best_flights: "بهترین پروازها",
      description: "ترتیب بر اساس قیمت و راحتی و امکانات"
    },
    best_solutions: {
      header: "بهترین پروازها",
      detail: "ترتیب بر اساس قیمت و راحتی و امکانات"
    },
    normal_solutions: "{number} پرواز  {origin} به {destination}",
    normal_solutions_loading: "در جستجوی پروازهای {origin} به {destination}...",
    others: "سایر",
    loading: {
      description: "در حال پیدا کردن بهترین پرواز‌‌ها برای شما هستیم.",
      title: "جست‌و‌جو‌‌ی‌ پروازهای {origin} به {destination}..."
    },
    filters: {
      submit: "مشاهده",
      result: "نتیجه",
      filter_visa: {
        title: "ویزای توقف ندارم",
        description: "توقف در بعضی کشور ها نیازمند ویزا است.",
        checkbox: "ویزای توقف {country} دارم."
      },
      filter_stops: {
        title: "تعداد توقف"
      },
      filter_airports: {
        title: "فرودگاه ها",
        airports: "فرودگاه های مبدا و مقصد",
        stop_airports: "فرودگاه های توقف",
        more: "نمایش بیشتر",
        less: "نمایش کمتر"
      }
    }
  },
  messages: {
    load_error_title: "خطایی رخ داده است!",
    load_error_main_description:
      "متاسفانه در بارگزاری اطلاعات خطایی رخ داده است.",
    load_error_sub_description: "لطفا مجدد تلاش کنید.",
    retry: "تلاش مجدد",
    loading_title: "در حال پیدا کردن بهترین پروازها برای شما هستیم.",
    loading_description: "جستجوی پروازهای ",
    to: "به",
    error_title: "خطایی رخ داده است!",
    error_description: "متاسفانه اطلاعاتی یافت نشد.",
    no_result_description: "لطفا روزهای دیگر را بررسی کنید.",
    return_to_homepage: "بازگشت به صفحه اصلی",
    not_found_title: "نتیجه‌ای یافت نشد.",
    not_found_description:
      "متاسفانه در حال حاضر پروازی متناسب با فیلتر های انتخابی شما وجود ندارد . ",
    delete_filters: "حذف فیلترها"
  },
  sort_filters: {
    title: "مرتب سازی",
    best_performance: "بهترین عملکرد",
    lowest_price: "ارزانترین",
    highest_price: "گرانترین",
    lowestـflightـduration: "کمترین مدت پرواز",
    earliest: "زودترین",
    latest: "دیرترین"
  },
  search: {
    departure_date: "تاریخ رفت",
    return_date: "تاریخ برگشت",
    origin: "مبدا",
    destination: "مقصد",
    one_way: "یک‌طرفه",
    round_trip: "رفت و برگشت",
    passenger: "مسافر",
    number_of_adult: "تعداد بزرگسال",
    number_of_child: "تعداد کودک",
    number_of_infant: "تعداد نوزاد",
    greather_than_n_years: "{age} سال به بالا",
    between_m_and_n_years: "{age_one} تا {age_two} سال",
    number_of_passenger: "تعداد مسافر",
    x_passenger: "{passenger_count} مسافر",
    economy: "اکونومی",
    business: "بیزینس",
    first_class: "فرست کلاس",
    class_type: "کلاس پرواز",
    trip_type: "نوع مسیر"
  },
  no_result: {
    title: "نتیجه ای یافت نشد.",
    description_first_part: "نتیجه ای برای ",
    description_second_part: " پیدا نکردیم.",
    search_another_word: "با کلمه ی دیگری جستجو کنید.",
    search_count: "بیشتر از {count} مورد را گشتیم ",
    but: " اما "
  },
  action: {
    ok: "تایید",
    cancel: "انصراف",
    reset: "پاک کردن",
    search: "بروزرسانی جستجو",
    changeTo: "تبدیل به"
  },
  solution_detail: {
    cancellation_policy: "کنسلی",
    baggage_policy: "بار",
    visa_policy: "ویزا",
    flight_policy: "قوانین",
    flight_details: "جزییات",
    baggage: {
      baggage: "بار و چمدان",
      allowed_baggage: "مقدار بار مجاز:"
    },
    flight_detail: {
      stop: "توقف",
      visa: "ویزای توقف ",
      to: "به",
      more_detail: "جزییات بیشتر",
      less_detail: "جزییات کمتر",
      fare_class: "کلاس نرخی",
      flight_number: "شماره پرواز ",
      stop_at: "توقف در",
      needed_visa: "نیاز به ویزای توقف",
      carrier_operator_airlines:
        "صدور این بلیت توسط {carrierAirlineName} و پرواز توسط {operatorAirlineName} انجام می‌شود."
    },
    travel_rules: {
      no_rule_text: "برای این پرواز و مسیر، {ruleType} یافت نشد.",
      important_rules: "قوانین بسیار مهم {ruleType}",
      general_rule: "قوانین عمومی {ruleType}",
      flight: "مسیر",
      visa: "ویزا",
      flight_rule: "قانونی",
      visa_rule: "قانون ویزایی"
    },
    cancellation_policies: {
      description:
        "درصد جریمه کسر شده بر اساس زمان ثبت درخواست کنسلی محاسبه می شود.",
      cancellation_time_condition: "شرایط زمانی کنسلی",
      penalty: "جریمه",
      table_description:
        "مطابق قوانین هواپیمایی کشوری اگر مسافر بلیط را کنسل کند، درصدی از هزینه بلیط به‌عنوان جریمه کنسلی از او دریافت می‌شود. درصد این جریمه را بسته به زمان اعلام کنسلی، در جدول جریمه‌ها آورده‌ایم. مابقی هزینه بلیط‌‌ حداکثر طی 72 ساعت به‌صورت آنلاین به حساب مسافر بازگردانده می‌شود.",
      no_policy_description:
        "برای اطلاع از قوانین و شرایط پرواز با شماره 92000123-021 تماس بگیرید."
    }
  },
  booking_page: {
    header: {
      title: "اطلاعات مسافران"
    },
    general: {
      select: "انتخاب",
      submit_passengers: "تایید و ادامه",
      passenger: "مسافر",
      adult: "بزرگسال",
      child: "کودک",
      infant: "نوزاد",
      child_age_range: "(۲ تا ۱۲ سال)",
      adult_age_range: "(بالای ۱۲ سال)",
      infant_age_range: "(۰ تا ۲ سال)"
    },
    stepper: {
      passenger_info: "اطلاعات مسافران",
      submit_pay: "پرداخت و خرید"
    },
    buyer_info_form: {
      title: "اطلاعات خریدار",
      dom_title: "نام خانوادگی و نام خریدار",
      form_fields: {
        name: "نام خریدار",
        family: "نام خانوادگی خریدار",
        mobile: "شماره موبایل",
        email: "آدرس ایمیل"
      },
      alert_desc:
        "اطلاعات بلیط‌های خریداری‌شده و اطلاع‌رسانی از تمام تغییرات در پرواز را به این شماره و ایمیل می‌فرستیم."
    },
    passengers_info_form: {
      title: "لیست مسافران",
      search: "جستجو نام، نام خانوادگی، کد ملی و شماره گذرنامه",
      country_modal: {
        search: "جستجو نام کشور"
      },
      genders: {
        male: "مرد",
        female: "زن"
      },
      form_fields: {
        select_previous_passengers: "انتخاب مسافران قبلی",
        name: "نام (انگلیسی مطابق گذرنامه)",
        family: "نام خانوادگی (انگلیسی مطابق گذرنامه)",
        gender: "جنسیت",
        nationality: "ملیت",
        birthday: "تاریخ تولد به میلادی",
        nationalId: "کد ملی",
        passportNumber: "شماره گذرنامه",
        passport_expiration_date: "تاریخ انقضای گذرنامه",
        passport_issue_place: "کشور صادرکننده گذرنامه"
      },
      previous_passengers_modal_title: "لیست مسافران",
      passenger_card: {
        nationalId: "کد ملی",
        passportNumber: "گذرنامه",
        expired_passport: "گذرنامه منقضی شده"
      }
    }
  }
};
