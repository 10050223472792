import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Button, LazyImage, ListItem } from "@website/ui";
import { FC } from "react";
import { Transition } from "react-transition-group";
import LOGO_EN from "../../assets/logo-en.png";
import LOGO from "../../assets/logo.png";
import LOGO_SNAPP from "../../assets/logo_snapp.svg";
import { useDrawer } from "./drawer.hook";
import styles from "./drawer.module.scss";
import { DrawerPropertiesType } from "./drawer.types";

export const Drawer: FC<DrawerPropertiesType> = (properties) => {
  const { services, onClose } = properties;
  const { locale, t } = useTranslation();
  const { transitionProperties } = useDrawer(properties);

  return (
    <Transition {...transitionProperties}>
      {(state) => (
        <div
          className={`${styles["container"]} ${
            state === "entering" || state === "entered" ? styles["open"] : null
          }`}
        >
          <div className={styles["header"]}>
            <Button
              EndIcon={<MaterialIcon name="arrow_forward" variant="outlined" />}
              aria-label="close"
              color="neutral"
              equalDimension
              onClick={onClose}
              size="small"
              variant="text"
            />
            <div className={styles["logo"]}>
              <a
                aria-label="snapptrip"
                href={process.env.NEXT_PUBLIC_BASE_URL_B2C}
                itemScope
              >
                <LazyImage
                  alt="snapptrip logo"
                  height={78}
                  loading="eager"
                  src={
                    process.env.NEXT_PUBLIC_CHANNEL_TYPE === "snapp-ir"
                      ? LOGO_SNAPP.src
                      : locale === "en"
                        ? LOGO_EN.src
                        : LOGO.src
                  }
                  title={t("header.logo")}
                  width={137}
                />
              </a>
            </div>
          </div>
          <nav className={styles["list"]}>
            {services?.map((item) => (
              <a
                className={styles["list-item"]}
                href={item.url}
                key={item.title}
                onClick={onClose}
              >
                <ListItem
                  EndIcon={
                    <MaterialIcon name="chevron_left" variant="outlined" />
                  }
                  StartIcon={item.icon}
                  data-noindex="true"
                  title={item.title}
                />
              </a>
            ))}
          </nav>
        </div>
      )}
    </Transition>
  );
};
