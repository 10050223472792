globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"1e8d7849d9e2fd9cfd4cc3f63258478e07d601d7"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import { init } from "@sentry/nextjs";

init({
  environment: process.env.NEXT_PUBLIC_BUILD_ENV,
  release: process.env.NEXT_PUBLIC_IMAGE_VERSION,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 0.2
});
