import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import {
  DEFAULT_CHECKED_VALUE,
  DEFAULT_UNCHECKED_VALUE
} from "./label-switch.constants";
import { TLabelSwitch } from "./label-switch.types";

const LabelSwitch: FC<TLabelSwitch> = ({
  value,
  onChange,
  checked = DEFAULT_CHECKED_VALUE,
  unChecked = DEFAULT_UNCHECKED_VALUE
}) => {
  const isChecked = value === checked.value;

  const handleToggle = () => {
    onChange(isChecked ? unChecked.value : checked.value);
  };
  const toggleMargin = 6;
  const toggleSize = 24;
  const labelWidth =
    Math.max(checked.label.length, unChecked.label.length) * 6 + 46;
  const toggleWidth = Math.max(labelWidth, 80);
  const checkedPosition = toggleWidth - toggleSize - toggleMargin;

  return (
    <div
      className={`relative inline-flex h-8 cursor-pointer items-center rounded-full p-1 transition-colors duration-300 ${
        isChecked ? "bg-[#22A958]" : "bg-gray-400"
      }`}
      onClick={handleToggle}
      style={{ width: toggleWidth }}
    >
      <div
        className="absolute size-6 rounded-full bg-white shadow-md transition-all duration-300"
        style={{ left: isChecked ? checkedPosition : toggleMargin }}
      />
      <span
        className={`absolute w-full whitespace-nowrap pr-4 text-center text-xs font-medium text-white transition-opacity duration-300 ${
          isChecked ? "opacity-100" : "opacity-0"
        }`}
        style={{
          left: 0,
          paddingRight: toggleSize + toggleMargin,
          paddingLeft: toggleMargin
        }}
      >
        {checked.label}
      </span>
      <span
        className={`absolute w-full whitespace-nowrap pr-1.5 text-center text-xs font-medium text-white transition-opacity duration-300 ${
          isChecked ? "opacity-0" : "opacity-100"
        }`}
        style={{
          right: 0,
          paddingLeft: toggleSize + toggleMargin,
          paddingRight: toggleMargin
        }}
      >
        {unChecked.label}
      </span>
    </div>
  );
};

const LabelSwitchMemoized = memo(LabelSwitch, deepCompare);
export { LabelSwitchMemoized as LabelSwitch };
