const commonPaths = new Set([
  "/",
  "/bus",
  "/bus-international",
  "/flights",
  "/tour",
  "/villa",
  "/international-hotel"
]);

export const pathsHasAuth = new Set([
  ...commonPaths,
  "/inter-flights",
  "/policy",
  "/about"
]);
export const pathsHasSearch = new Set([
  ...commonPaths,
  ...(process.env.NEXT_PUBLIC_CHANNEL_TYPE === "jek"
    ? ["/train", "/home-visa", "/interflights"]
    : ["/inter-flights"])
]);
