import { RadioGroup } from "@website/ui";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { RadioTypes } from "../filter.types";
import styles from "../index.module.scss";

interface RadioProperties extends RadioTypes {
  name: string;
}

export const RadioFilter = ({
  name,
  title,
  description,
  type,
  defaultValue,
  items
}: RadioProperties) => {
  const { control, setValue } = useFormContext();
  const router = useRouter();

  const parsedInitialValues = useMemo(
    () => String(router.query[name] ?? defaultValue),
    [defaultValue, name, router.query]
  );

  useEffect(() => {
    setValue(name, { type, value: parsedInitialValues });
  }, [name, parsedInitialValues, setValue, type]);

  return (
    <div className={styles["radio-filter-container"]}>
      <div className="grid">
        <div className={styles["filter-header"]} id="has-availability">
          {title}
        </div>
        <span className={styles["has-availability"]}>{description}</span>
      </div>

      <Controller
        control={control}
        name={`${name}.value`}
        render={({ field }) => (
          <RadioGroup
            className="flex-col !items-start"
            color="secondary"
            itemClassName="text-subtitle-2"
            items={items}
            {...field}
          />
        )}
      />
    </div>
  );
};
