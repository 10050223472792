import { useDebounce } from "@website/hooks";
import { LodgingPin, Origin } from "@website/icons";
import { useTranslation } from "@website/locale";
import { numberConvert } from "@website/utils";
import { useCallback, useMemo, useState } from "react";
import { NoResult } from "../../../..";
import { SUGGESTIONS_LIST } from "../search-hotel.constant";
import styles from "../search-hotel.module.scss";
import { useSearchTextService } from "../services";

export const useDestination = () => {
  const [destinationQuery, setDestinationQuery] = useState<string>("");
  const [selectedDestination, setSelectedDestination] = useState<string>("");
  const [selectedDestinationItem, setSelectedDestinationItem] = useState(null);
  const { locale } = useTranslation();
  const query = useDebounce(destinationQuery, 250);

  const { isLoading, data } = useSearchTextService(query);

  const onChangeDestinationQuery = useCallback((value: string) => {
    setDestinationQuery(value);
  }, []);

  const destinationList = useMemo(
    () => (destinationQuery.length >= 2 ? data || [] : SUGGESTIONS_LIST || []),
    [data, destinationQuery]
  );

  const destinationListNormalized = useMemo(
    () =>
      destinationList.map((item) => ({
        id: `${item.id}`,
        title: item.fa_title,
        subtitle:
          "accommodation_type_title" in item
            ? item.fa_city_title
            : item.fa_state_title,
        StartIcon:
          "accommodation_type_title" in item ? (
            <LodgingPin className={styles["autocomplete-hotel-icon"]} />
          ) : (
            <Origin className={styles["autocomplete-city-icon"]} />
          ),
        Meta: (
          <div className="text-on-surface-high-emphasis text-caption">
            {"accommodation_type_title" in item
              ? `${numberConvert(item.stars, { locale })} ستاره`
              : `${numberConvert(item.hotel_count, { locale })} اقامتگاه`}
          </div>
        )
      })),
    [destinationList, locale]
  );

  const onSelectDestination = useCallback(
    (id: string) => {
      setSelectedDestination(id);
      const destinationItem = destinationList.find(
        (destination: any) => `${destination.id}` === `${id}`
      );
      setDestinationQuery("");
      setSelectedDestinationItem(destinationItem);
    },
    [destinationList]
  );

  const onChangeDestination = useCallback((item: any) => {
    setSelectedDestination(item.id);
    setDestinationQuery("");
    setSelectedDestinationItem(item);
  }, []);

  return {
    selectedDestinationItem,
    selectedDestinationText: selectedDestinationItem?.fa_title || "",
    destinationQuery,
    selectedDestination,
    onChangeDestination,
    onSelectDestination,
    onChangeDestinationQuery,
    destinationList,
    destinationLoading: isLoading,
    destinationListNormalized,
    DestinationNoResult:
      destinationQuery?.length >= 2 &&
      !isLoading &&
      data &&
      data.length === 0 ? (
        <NoResult query={destinationQuery} />
      ) : undefined
  };
};
