import { deepCompare } from "@website/utils";
import { memo } from "react";
import { ImageType } from "../reserve-gallery/reserve-gallery.types";
import { ReserveCommentAvatar } from "./reserve-comment-avavtar";
import { ReserveCommentHeader } from "./reserve-comment-header";
import { ReserveCommentPictures } from "./reserve-comment-pictures";
import { ReserveCommentPoints } from "./reserve-comment-points";
import { ReserveCommentPropertiesType } from "./reserve-comment.types";

const Component = (properties: ReserveCommentPropertiesType) => {
  const {
    id,
    userName,
    roomName,
    rate,
    registeredDate,
    standNights,
    comment,
    strengths,
    weakness,
    source,
    pictures
  } = properties;
  const commentPicturesProperties = {
    id,
    roomName,
    standNights,
    registeredDate,
    gallery: pictures as Array<ImageType>
  };
  return (
    <div
      className="shadow-1 bg-dim-background flex w-full flex-col gap-4 overflow-hidden rounded-xl p-4 xl:p-6"
      dir={source === "booking.com" ? "ltr" : "rtl"}
    >
      <div className="flex flex-row">
        <div className="hidden shrink pe-2 before:hidden before:size-[48px] before:rounded-full before:border before:content-[''] xl:block before:xl:inline-block">
          <ReserveCommentAvatar
            className="mx-auto mt-[-48px] size-auto"
            rate={rate}
          />
        </div>
        <div className="flex grow flex-col gap-4">
          <ReserveCommentHeader
            properties={{
              userName,
              roomName,
              registeredDate,
              standNights,
              source,
              rate
            }}
          />
          {comment && (
            <div className="text-caption xl:text-body-2">{comment}</div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-3">
        {strengths && (
          <ReserveCommentPoints
            properties={{
              message: strengths,
              type: "strengths"
            }}
          />
        )}
        {weakness && (
          <ReserveCommentPoints
            properties={{
              message: weakness,
              type: "weakness"
            }}
          />
        )}
      </div>
      <div>
        {pictures && pictures?.length > 0 && (
          <ReserveCommentPictures {...commentPicturesProperties} />
        )}
      </div>
    </div>
  );
};
export const ReserveComment = memo(Component, deepCompare);
