import { flight_domestic } from "./flight_domestic/ar";
import { flight_international } from "./flight_international/ar";
import { flight_international_visa } from "./flight_international_visa/ar";
import { home_ui } from "./home_ui/ar";
import { homepage } from "./homepage/ar";
import { hotel_international } from "./hotel_international/ar";
import { profile } from "./profile/ar";
import { ticket_ui } from "./ticket_ui/ar";

export const ar = {
  homepage,
  hotel_international,
  flight_international_visa,
  flight_international,
  profile,
  ticket_ui,
  home_ui,
  flight_domestic
};
