import { AxiosResponse } from "axios";
import { AxiosNoAuth } from "../../../../utils";
import { SearchHotelianResponseType } from "./search-text.types";

export const SearchTextUrlHotelian = `${process.env.NEXT_PUBLIC_BASE_URL_HAPI}/international-hotel/api/suggest?query=`;
export const SearchTextServiceHotelian = (
  query: string
): Promise<SearchHotelianResponseType> =>
  AxiosNoAuth.get(`${SearchTextUrlHotelian}${query}`).then(
    ({ data }: AxiosResponse<SearchHotelianResponseType>) => data
  );
