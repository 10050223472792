import { MaterialIcon } from "@website/icons";
import { Skeleton } from "@website/ui";
import { deepCompare } from "@website/utils";
import { useRouter } from "next/router";
import { cloneElement, FC, memo } from "react";
import { SearchSummary } from "../search-summary";
import useSearchWrapper from "./hooks";
import { SearchWrapperPropertiesType } from "./types";

const SearchWrapper: FC<SearchWrapperPropertiesType> = (properties) => {
  const { searchSummaryProperties, SearchPanel, title } = properties;
  const { isReady } = useRouter();

  const {
    isSearchPanelOpen,
    handleClose,
    handleOpen,
    isSearchContainerScrolled,
    scrolledReference
  } = useSearchWrapper();
  return (
    <>
      <div ref={scrolledReference} />

      <div
        className={`border-outline-thin sticky top-0 z-30 border-y bg-white ${
          isSearchContainerScrolled ? "shadow-4" : ""
        }`}
      >
        <div className="flex justify-center px-6 py-2 md:py-4">
          {isReady ? (
            <SearchSummary {...searchSummaryProperties} onClick={handleOpen} />
          ) : (
            <div className="w-96">
              <Skeleton height={42} />
            </div>
          )}
        </div>
        <section
          className={`fixed top-0 z-30 max-h-0 w-full overflow-hidden bg-white transition-all duration-75 max-lg:h-0 lg:absolute ${
            isSearchPanelOpen
              ? "h-auto max-h-screen overflow-visible max-lg:!h-screen"
              : ""
          } `}
        >
          <div
            className={`mx-auto flex size-full flex-col opacity-0 transition-all duration-500 max-md:p-6 md:px-6 md:pb-10 md:pt-6 xl:max-w-[1200px] ${
              isSearchPanelOpen ? "opacity-100" : ""
            }`}
          >
            <div className="mb-4 flex h-20 w-full flex-col items-center gap-3 lg:hidden">
              <MaterialIcon
                className="text-on-surface-medium-emphasis mr-auto text-2xl ltr:ml-auto"
                name="arrow_forward"
                onClick={handleClose}
              />
              <h6 className="text-headline-6 text-on-surface-high-emphasis ml-auto ltr:mr-auto">
                {title}
              </h6>
            </div>
            {isReady && SearchPanel
              ? cloneElement(SearchPanel, { onClose: handleClose })
              : null}
          </div>
        </section>
      </div>
      <button
        className={`bg-gray fixed right-0 top-0 z-20 h-screen w-screen opacity-40 ${
          isSearchPanelOpen ? "block" : "hidden"
        }`}
        onClick={handleClose}
        type="button"
      />
    </>
  );
};

export default memo(SearchWrapper, deepCompare);
