import { useTranslation } from "@website/locale";
import { ChangeEvent, useCallback, useState } from "react";
import { AuthB2BRequestOtpService } from "../../services/auth-b2b-request-otp";
import { isPhoneNumberPartiallyValid, isPhoneNumberValid } from "../../utils";
import { B2BInquiryPropertiesTypes } from "./types";

export const useB2BInquiry = (properties: B2BInquiryPropertiesTypes) => {
  const { setStep, setUserData, userData } = properties;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [contractId, setContractId] = useState<string>("");
  const { t } = useTranslation();

  const onChangePhoneNumber = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (isPhoneNumberPartiallyValid(value)) {
        setPhoneNumber(value);
      }
    },
    []
  );

  const onChangeContractId = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setContractId(value);
    },
    []
  );

  const onSubmitPhoneNumber = useCallback(async () => {
    if (isPhoneNumberValid(phoneNumber) || contractId) {
      setIsLoading(true);
      setError("");
      setStep("otp");
      AuthB2BRequestOtpService({ phoneNumber })
        .then((data) => {
          setUserData({ ...userData, ...data, phoneNumber, contractId });
        })
        .catch((error) => {
          setError(
            error?.response?.data?.message || t("login.general_error_message")
          );
        })
        .finally(() => setIsLoading(false));
    } else {
      setError("اطلاعات وارد شده صحیح نمیباشد.");
    }
  }, [contractId, phoneNumber, setStep, setUserData, t, userData]);

  return {
    error,
    isLoading,
    phoneNumber,
    contractId,
    onChangePhoneNumber,
    onSubmitPhoneNumber,
    onChangeContractId
  };
};
