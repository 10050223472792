export const SUGGESTIONS_LIST = [
  {
    id: 6433,
    en_url: "tehran",
    fa_url: "تهران",
    en_state_title: "تهران",
    fa_state_title: "تهران",
    hotel_count: 120,
    fa_title: "تهران",
    en_title: "Tehran",
    provider: "snapptrip"
  },
  {
    id: 6497,
    en_url: "mashhad",
    fa_url: "مشهد",
    en_state_title: "خراسان رضوی",
    fa_state_title: "خراسان رضوی",
    hotel_count: 186,
    fa_title: "مشهد",
    en_title: "Mashhad",
    provider: "snapptrip"
  },
  {
    id: 6640,
    en_url: "shiraz",
    fa_url: "شیراز",
    en_state_title: "فارس",
    fa_state_title: "فارس",
    hotel_count: 82,
    fa_title: "شیراز",
    en_title: "Shiraz",
    provider: "snapptrip"
  },
  {
    id: 6918,
    en_url: "kish",
    fa_url: "کیش",
    en_state_title: "هرمزگان",
    fa_state_title: "هرمزگان",
    hotel_count: 28,
    fa_title: "کیش",
    en_title: "Kish",
    provider: "snapptrip"
  },
  {
    id: 6326,
    en_url: "isfahan",
    fa_url: "اصفهان",
    en_state_title: "اصفهان",
    fa_state_title: "اصفهان",
    hotel_count: 87,
    fa_title: "اصفهان",
    en_title: "Isfahan",
    provider: "snapptrip"
  },
  {
    id: 6220,
    en_url: "tabriz",
    fa_url: "تبریز",
    en_state_title: "آذربایجان شرقی",
    fa_state_title: "آذربایجان شرقی",
    hotel_count: 22,
    fa_title: "تبریز",
    en_title: "Tabriz",
    provider: "snapptrip"
  },
  {
    id: 6984,
    en_url: "kashan",
    fa_url: "کاشان",
    en_state_title: "اصفهان",
    fa_state_title: "اصفهان",
    hotel_count: 45,
    fa_title: "کاشان",
    en_title: "Kashan",
    provider: "snapptrip"
  },
  {
    id: 6931,
    en_url: "qeshm",
    fa_url: "قشم",
    en_state_title: "هرمزگان",
    fa_state_title: "هرمزگان",
    hotel_count: 76,
    fa_title: "قشم",
    en_title: "Qeshm",
    provider: "snapptrip"
  }
];

export const HOTEL_DESTINATION_HISTORY_LOCAL_STORAGE_KEY =
  "hotel-destination-history";
export const HOTEL_SEARCH_HISTORY_LOCAL_STORAGE_KEY = "hotel-search-history";
