import { useViewPort } from "@website/hooks";
import { deepOmit, isFilled } from "@website/utils";
import {
  debounce,
  flatMap,
  fromPairs,
  get,
  isArray,
  omit,
  omitBy,
  toNumber,
  toPairs
} from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useRef } from "react";
import type { FilterResultType, FiltersSchemaType } from "./filter.types";

type UseFiltersProperties = {
  schema: FiltersSchemaType;
};

export const useFilters = ({ schema }: UseFiltersProperties) => {
  const router = useRouter();

  const setFiltersQueryParameters = useCallback(
    (filtersUpdateObject: Record<string, string | Array<string>>) =>
      router.replace(
        {
          pathname: router.pathname,
          query: deepOmit(
            {
              ...router.query,
              ...filtersUpdateObject
            },
            isFilled
          )
        },
        undefined,
        { scroll: true, shallow: true }
      ),
    [router]
  );

  const setFiltersQueryParametersWithDebounce = useMemo(
    () =>
      debounce(
        (fieldName, adaptedQueryValue) =>
          setFiltersQueryParameters({
            [fieldName]: adaptedQueryValue
          }),
        500
      ),
    [setFiltersQueryParameters]
  );

  const setFilterQueries = useCallback(
    ({ name, asQuery, withDebounce }: FilterResultType) =>
      withDebounce
        ? setFiltersQueryParametersWithDebounce(name, asQuery)
        : setFiltersQueryParameters({
            [name]: asQuery
          } as Record<string, string | Array<string>>),
    [setFiltersQueryParameters, setFiltersQueryParametersWithDebounce]
  );

  const initialFiltersValues = fromPairs(
    toPairs(schema).map(([name, schema]) => {
      const value = router.query[name];
      if (isArray(value)) return [name, undefined];
      switch (schema.type) {
        case "checkbox":
        case "visa": {
          return [name, value?.split(",")];
        }
        case "multi-checkbox": {
          const { options } = schema;
          const array: Array<string> = [];
          options
            ?.map((item) => router.query[item.key]?.toString().split(","))
            .map(
              (items) => items?.map((item) => Boolean(item) && array.push(item))
            );
          return [name, array];
        }

        case "switch":
        case "counter": {
          return [name, value ?? get(schema, "defaultValue")];
        }

        case "radio": {
          return [name, value ?? get(schema, "defaultValue")];
        }

        case "range": {
          const { min_key, max_key } = schema;
          const [min, max] = [router.query[min_key], router.query[max_key]];

          return [
            name,
            [toNumber(min), toNumber(max)].filter(Boolean) ??
              get(schema, "defaultValue")
          ];
        }
        case "input": {
          const { search_key } = schema;
          const id = router.query[search_key];

          return [name, id ?? ""];
        }

        case "time-range": {
          const { min_key, max_key } = schema;
          const [min_source, max_source] = [
            router.query[min_key[0]],
            router.query[max_key[0]]
          ];
          const [min_destination, max_destination] = [
            router.query[min_key[1]],
            router.query[max_key[1]]
          ];
          const array: Array<number> = [];
          [
            [toNumber(min_source), toNumber(max_source)],
            [toNumber(min_destination), toNumber(max_destination)]
          ].map(
            (items) => items?.map((item) => Boolean(item) && array.push(item))
          );

          return [name, array];
        }
        default: {
          return [name, undefined];
        }
      }
    })
  );

  const selectedFiltersCount = flatMap(
    omitBy(
      initialFiltersValues,
      (value, key) =>
        !value ||
        !isFilled(value) ||
        (isArray(value) && value.length === 0) ||
        (get(schema, `${key}.defaultValue`) &&
          value === get(schema, `${key}.defaultValue`)) ||
        Number(value) === Number(get(schema, `${key}.defaultValue`))
    )
  ).length;

  return { selectedFiltersCount, setFilterQueries };
};

export const useFilterLayoutEffect = () => {
  const formReference = useRef<HTMLFormElement>(null);
  const lastScrollPosition = useRef(0);
  const { isMobile } = useViewPort();

  useEffect(() => {
    const syncScrollBar = () => {
      if (!isMobile && formReference.current) {
        formReference.current.scrollTop +=
          window.scrollY - lastScrollPosition.current;
        lastScrollPosition.current = window.scrollY;
      }
    };
    window.addEventListener("scroll", syncScrollBar);
    return () => window.removeEventListener("scroll", syncScrollBar);
  }, [isMobile]);

  return { formRef: formReference, isMobile };
};

export function useCityFilterState() {
  const router = useRouter();

  const filterQueryName = "filters_visible";

  const visible = router.query[filterQueryName] === "true";

  const onOpenFilters = () => {
    const url = {
      pathname: router.pathname,
      query: deepOmit(
        { [filterQueryName]: true, ...omit(router.query, filterQueryName) },
        isFilled
      )
    };
    return router.push(url, undefined, { shallow: true });
  };

  const onCloseFilters = () => {
    const url = {
      pathname: router.pathname,
      query: deepOmit({ ...omit(router.query, filterQueryName) }, isFilled)
    };

    return router.replace(url, undefined, { shallow: true });
  };

  return { visible, onOpenFilters, onCloseFilters };
}
