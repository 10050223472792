import { AxiosResponse } from "axios";
import { Axios } from "../../utils";
import { BASE_URL_PROFILE } from "../urls";
import {
  AuthB2BRequestOtpBodyType,
  AuthB2BRequestOtpResponseType
} from "./auth-b2b-request-otp.types";

export const AuthB2BRequestOtpUrl = `${BASE_URL_PROFILE}/b2b/auth/request-otp`;

export const AuthB2BRequestOtpService = (
  body: AuthB2BRequestOtpBodyType
): Promise<AuthB2BRequestOtpResponseType> =>
  Axios.post(AuthB2BRequestOtpUrl, body).then(
    ({ data }: AxiosResponse<AuthB2BRequestOtpResponseType>) => data
  );
