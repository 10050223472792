import { CarouselV2 } from "@website/ui";
import { Skeleton } from "@website/ui-old";
import { FC, memo, useState } from "react";
import styles from "../banner-medium/banner-medium.module.scss";
import { BannerMediumItem } from "../banner-medium/components";
import { BannerSlidePropertiesType } from "./banner-slide.types";

const BannerSlide: FC<BannerSlidePropertiesType> = (properties) => {
  const {
    list,
    isLoading = false,
    className,
    width,
    height = 300
  } = properties;
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return isLoading ? (
    <Skeleton height={height} />
  ) : list?.length > 0 ? (
    <CarouselV2
      itemClass={`${className} ${styles["item"]}`}
      keyCarousel={`BannerMedium-${list[selectedTab]?.bannerId}`}
      list={list.map((item, index) => (
        <BannerMediumItem
          key={index++}
          {...{
            ...item,
            id: index,
            height: height || item.height,
            width: width || item.width,
            onClick: () => setSelectedTab(index)
          }}
        />
      ))}
      mode="free"
      options={{
        slides: {
          perView: "auto",
          spacing: 16
        }
      }}
    />
  ) : null;
};

const BannerSlideMemoized = memo(BannerSlide);
export { BannerSlideMemoized as BannerSlide };
