import { Skeleton } from "@website/ui";
import dynamic from "next/dynamic";

export const ReserveMap = dynamic(
  () =>
    import("./reserve-map").then(
      ({ ReserveMap: HotelReserveMap }) => HotelReserveMap
    ),
  {
    loading: () => <Skeleton className="size-full" />
  }
);

export * from "./reserve-map.types";
