import { AxiosResponse } from "axios";
import { AxiosNoAuth } from "../../../utils";
import { SearchParametersType, SearchResponseType } from "./search.types";

export const SearchService = (
  parameters: SearchParametersType
): Promise<SearchResponseType> =>
  AxiosNoAuth.get(`${process.env.NEXT_PUBLIC_BASE_URL_HAPI}/search/v2/villa`, {
    params: parameters
  }).then(({ data }: AxiosResponse<SearchResponseType>) => data);
