import { applicationStore } from "@website/store";
import Script from "next/script";
import { FC, useEffect } from "react";
import { GoogleTagManager } from "./google-tag-manager";
import { Webengage } from "./webengage";

export const Scripts: FC<{
  webengageLicence?: string;
  gtmLicence?: string;
}> = ({ webengageLicence, gtmLicence }) => {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_BUILD_ENV === "production") {
      navigator?.serviceWorker?.register(
        `/sw.js?v=${process.env.NEXT_PUBLIC_IMAGE_VERSION}`
      );
    }
  }, []);
  return (
    <>
      {gtmLicence && <GoogleTagManager licence={gtmLicence} />}
      {webengageLicence && <Webengage licence={webengageLicence} />}
      {applicationStore.isNative.get() && (
        <Script
          src="https://web-cdn.snapp.ir/shared/scripts/super-app-api.js"
          strategy="afterInteractive"
        />
      )}
    </>
  );
};
